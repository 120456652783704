import React, { useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import './RecuperacaoEmail.css';

const RecuperacaoEmail = () => {
  const [codigo, setCodigo] = useState("");
  const [email, setEmail] = useState(""); // Captura o email para enviar junto com o código
  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/verify-recovery-code", { email, code: codigo })
      .then((response) => {
        alert(response.data.message);
        history.push("/mudarsenha"); // Redireciona para a página de mudança de senha
      })
      .catch((error) => {
        alert("Erro ao verificar o código de recuperação: " + error.message);
      });
  };

  return (
    <div className="container-recuperacao-email">
      <Container component="main" maxWidth="xs" className="card-recuperacao-email">
        <Typography component="h1" variant="h5">
          Insira o código de recuperação
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="codigo"
            label="Código de Recuperação"
            name="codigo"
            autoFocus
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Mudar Senha
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default RecuperacaoEmail;
