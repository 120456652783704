import React, { useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import './MudarSenha.css';

const MudarSenha = () => {
  const [novaSenha, setNovaSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [email, setEmail] = useState(""); // Captura o email associado

  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (novaSenha !== confirmarSenha) {
      alert("As senhas não coincidem.");
      return;
    }
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/reset-password", { email, newPassword: novaSenha })
      .then((response) => {
        alert(response.data.message);
        history.push("/login"); // Redireciona para a página de login
      })
      .catch((error) => {
        alert("Erro ao redefinir a senha: " + error.message);
      });
  };

  return (
    <div className="container-mudar-senha">
      <Container component="main" maxWidth="xs" className="card-mudar-senha">
        <Typography component="h1" variant="h5">
          Mudar Senha
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="novaSenha"
            label="Nova Senha"
            type="password"
            id="novaSenha"
            autoComplete="new-password"
            value={novaSenha}
            onChange={(e) => setNovaSenha(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmarSenha"
            label="Confirmar Nova Senha"
            type="password"
            id="confirmarSenha"
            autoComplete="new-password"
            value={confirmarSenha}
            onChange={(e) => setConfirmarSenha(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Registrar
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default MudarSenha;
