import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import './LoginPaciente.css';

export default function LoginPaciente() {
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");

  Axios.defaults.withCredentials = true;

  const login = () => {
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/loginPaciente", {
      withCredentials: true,
      headers: {
        "Access-Allow-Control-Origin": "http://localhost:3000",
      },
      username: username,
      password: password,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setLoginStatus(response.data[0].nome);
          history.push("/solicitante");
      }
    }).catch((error) => {
      console.error("Houve um erro ao tentar fazer login:", error);
    });
  };

  const cadastro = () => {
    history.push("/escolha-cadastro");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  useEffect(() => {
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaLogin", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
          history.push("/solicitante");
      }
    }).catch((error) => {
      console.error("Houve um erro ao verificar o login:", error);
    });
  }, [history]);
  return (
    <>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-form validate-form">
              <span className="login100-form-title">
                Olá Paciente!!
              </span>
              <div className="wrap-input100 validate-input">
                <input
                  className="input100"
                  type="email"
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Email"
                ></input>
              </div>
              <div className="wrap-input100 validate-input">
                <input
                  className="input100"
                  type="password"
                  onKeyPress={handleKeyPress}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Senha"
                ></input>
              </div>
              <div className="text-right">
                <a href="http://localhost:3000/esqueceusenha" className="txt2">
                  Esqueceu a senha?
                </a>
              </div>
              <div className="container-login100-form-btn">
                <button type="submit" onClick={login} className="login100-form-btn">
                  Entrar
                </button>
              </div>
              <div className="container-login100-form-btn">
                <button
                  type="submit"
                  onClick={cadastro}
                  className="login100-form-btn"
                >
                  Cadastrar-se
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
