
import React, { useState } from 'react';
import Axios from 'axios';
import { getCurrentDate } from '../../utils/date';
import { isValidCPF } from '../../utils/valida_cpf';
import InputMask from "react-input-mask";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '../Login/login.css';
import './CadastroPaciente.css';
import { useHistory } from "react-router";

const CadastroPaciente = (props) => {
  let history = useHistory();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaConfirma, setSenhaConfirma] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [uf, setUf] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const register = (e) => {
    e.preventDefault();
    const endereco = `${rua}, ${numero}, ${complemento}, ${bairro}, ${cidade}, ${uf}, ${cep}`;
    if (
     /* nome === "" ||
      email === "" ||
      senha === "" ||
      senhaConfirma === "" ||
      nascimento === "" ||
      cpf === "" ||
      rg === "" ||
      telefone === "" ||
      cep === "" ||
      uf === "" ||
      cidade === "" ||
      bairro === "" ||
      rua === "" ||
      numero === "" ||
      complemento === ""*/
      nome === ""
    ) {
      alert("Preencha todos os campos");
    } else {
      if (senha === senhaConfirma) {
        if (isValidCPF(cpf)) {
          handleOpen();
        } else {
          alert("CPF inválido");
        }
      } else {
        alert("As senhas não coincidem");
      }
    }
  };

  const confirmarCadastro = () => {
    const endereco = `${rua}, ${numero}, ${complemento}, ${bairro}, ${cidade}, ${uf}, ${cep}`;
    const cepAddr = cep;
    const city = cidade;
    const estado = uf;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/registerPaciente", {
      nome: nome,
      senha: senha,
      email: email,
      nascimento: nascimento,
      cpf: cpf,
      rg: rg,
      numero: telefone,
      endereco: endereco,
      cidade: city,
      estado: estado,
      cep: cepAddr,
      dataCad: getCurrentDate(),
    }).then((response) => {alert("Cadastro realizado com sucesso.");
      history.push("/loginpaciente"); 
    })

    
    handleClose();
  };

  const handleBlur = () => {
    preencheCEP(cep);
  };

  const preencheCEP = () => {
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/preencheCEP", {
      headers: {
        "Access-Allow-Control-Origin": "http://localhost:3000",
      },
      cep: cep,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setCep(response.data.cep);
        setBairro(response.data.bairro);
        setRua(response.data.logradouro);
        setCidade(response.data.localidade);
        setUf(response.data.uf);
      }
    });
  };

  Axios.defaults.withCredentials = false;

  return (
    <>
      <Container component="main" maxWidth="xl" sx={{ height: "100vh", display: "flex" }} >
        <CssBaseline>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h3">
              CADASTRAR PACIENTE
            </Typography>
            <Box component="form" noValidate sx={{ mb: 8, mt: 8, mx: 'auto' }}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="nome"
                    name="nome"
                    required
                    fullWidth
                    id="nome"
                    label="Nome"
                    autoFocus
                    value={nome}
                    onChange={(e) => {
                      setNome(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="email"
                    name="email"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    autoFocus
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="senha"
                    name="senha"
                    required
                    fullWidth
                    id="senha"
                    label="Senha"
                    type="password"
                    autoFocus
                    value={senha}
                    onChange={(e) => {
                      setSenha(e.target.value);
                    }}
                  />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="senhaConfirma"
                    name="senhaConfirma"
                    required
                    fullWidth
                    id="senhaConfirma"
                    label="Confirme sua senha"
                    type="password"
                    autoFocus
                    value={senhaConfirma}
                    onChange={(e) => {
                      setSenhaConfirma(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="bday"
                    name="nascimento"
                    required
                    fullWidth
                    id="nascimento"
                    label="Data de Nascimento"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoFocus
                    value={nascimento}
                    onChange={(e) => {
                      setNascimento(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="999.999.999-99"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    onBlur={handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        autoComplete="cpf"
                        name="cpf"
                        required
                        fullWidth
                        id="cpf"
                        label="CPF"
                        autoFocus
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="rg"
                    name="rg"
                    required
                    fullWidth
                    id="rg"
                    label="RG"
                    autoFocus
                    value={rg}
                    onChange={(e) => {
                      setRg(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="(99) 99999-9999"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        autoComplete="tel"
                        name="telefone"
                        required
                        fullWidth
                        id="telefone"
                        label="Telefone"
                        type="tel"
                        autoFocus
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                <InputMask
                    mask="99999-999"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        autoComplete="cep"
                        name="cep"
                        required
                        fullWidth
                        id="cep"
                        label="CEP"
                        autoFocus
                        />
                      )}
                    </InputMask>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ minWidth: "100%" }}>
                    <InputLabel id="Uf-label">UF</InputLabel>
                    <Select
                      labelId="Uf-label"
                      id="Uf"
                      autoComplete="UF"
                      label="UF"
                      autoWidth
                      required
                      fullWidth
                      autoFocus
                      value={uf}
                      onChange={(e) => {
                        setUf(e.target.value);
                      }}
                    >
                      <MenuItem value="AC">Acre</MenuItem>
                      <MenuItem value="AL">Alagoas</MenuItem>
                      <MenuItem value="AP">Amapá</MenuItem>
                      <MenuItem value="AM">Amazonas</MenuItem>
                      <MenuItem value="BA">Bahia</MenuItem>
                      <MenuItem value="CE">Ceará</MenuItem>
                      <MenuItem value="DF">Distrito Federal</MenuItem>
                      <MenuItem value="ES">Espírito Santo</MenuItem>
                      <MenuItem value="GO">Goiás</MenuItem>
                      <MenuItem value="MA">Maranhão</MenuItem>
                      <MenuItem value="MT">Mato Grosso</MenuItem>
                      <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                      <MenuItem value="MG">Minas Gerais</MenuItem>
                      <MenuItem value="PA">Pará</MenuItem>
                      <MenuItem value="PB">Paraíba</MenuItem>
                      <MenuItem value="PR">Paraná</MenuItem>
                      <MenuItem value="PE">Pernambuco</MenuItem>
                      <MenuItem value="PI">Piauí</MenuItem>
                      <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                      <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                      <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                      <MenuItem value="RO">Rondônia</MenuItem>
                      <MenuItem value="RR">Roraima</MenuItem>
                      <MenuItem value="SC">Santa Catarina</MenuItem>
                      <MenuItem value="SP">São Paulo</MenuItem>
                      <MenuItem value="SE">Sergipe</MenuItem>
                      <MenuItem value="TO">Tocantins</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="cidade"
                    name="cidade"
                    required
                    fullWidth
                    id="cidade"
                    label="Cidade"
                    autoFocus
                    value={cidade}
                    onChange={(e) => {
                      setCidade(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="bairro"
                    name="bairro"
                    required
                    fullWidth
                    id="bairro"
                    label="Bairro"
                    autoFocus
                    value={bairro}
                    onChange={(e) => {
                      setBairro(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="rua"
                    name="rua"
                    required
                    fullWidth
                    id="rua"
                    label="Rua"
                    autoFocus
                    value={rua}
                    onChange={(e) => {
                      setRua(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="numero"
                    name="numero"
                    required
                    fullWidth
                    id="numero"
                    label="Número"
                    autoFocus
                    value={numero}
                    onChange={(e) => {
                      setNumero(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <TextField
                    autoComplete="complemento"
                    name="complemento"
                    required
                    fullWidth
                    id="complemento"
                    label="Complemento"
                    value={complemento}
                    autoFocus
                    onChange={(e) => {
                      setComplemento(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                  <button
                    type="submit"
                    className="login100-form-btn"
                    onClick={register}
                  >
                    Cadastrar
                  </button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </CssBaseline>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="modal"
      >
          <Box className="modal-box" sx={{ minWidth: 800, minHeight: 500 }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Termos de Adesão ao Serviço
          </Typography>
          <Typography id="modal-description" className="modal-description" sx={{ mt: 2 }}>
          Informamos que para ter acesso a consulta farmacêutica por vídeo chamada você terá que se cadastrar na plataforma da MyDIGICARE para criar um Login e senha. Seu teleatendimento farmacêutico será registrado e armazenado em um banco de dados especializado. Serão solicitadas informações pessoais, de localização e de saúde, que serão incorporadas à sua ficha de anamnese e prontuário farmacêutico, podendo ser requisitadas a qualquer momento. Seus dados poderão ser compartilhados com outro profissional da saúde, exclusivamente mediante a sua autorização ou para efeitos de discussão de sua condição clínica com outro profissional da saúde.
          <br/>
          <br/>
          <div className="button-area">
          <Button variant="contained" onClick={confirmarCadastro} className="modal-button">
            Confirmar
          </Button>
          </div></Typography>

          
        </Box>
      </Modal>
    </>
  );
};

export default CadastroPaciente;
