import React, { useState, useEffect } from "react";
import SidebarSolicitante from '../../components/sidebar_solicitante/Sidebar_Solicitante';
import InputMask from "react-input-mask";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Success from './assets/success.svg'

const ConfirmaPagamentoSolicitante = (props) => {

    Axios.defaults.withCredentials = true;
    const [cardNumber, setNumCartao] = useState('');
    const [nomeTitular, setNomeTitular] = useState('');
    const [dataExp, setDataExp] = useState('');
    const [cvv, setCVV] = useState('');
    const [username, setUsuario] = useState({});
    const [usernamePagaMe, setUsuarioPagaMe] = useState({});
    let history = useHistory();

    useEffect(() => {
        Axios.defaults.withCredentials = true;
        Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/login", {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        }).then((response) => {
          if (response.data.loggedIn === true) {
            setUsuario(response.data.user[0]);
          }
        });
      }, []);

      const handlePagaMe=() =>{
        Axios.defaults.withCredentials = true;
        Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/api/paciente/cadastrar",{
            headers: {
                "Access-Allow-Control-Origin": "http://localhost:3000",
              },
                name: username.nome,
                email: username.email,
                document_number: username.cpf,
                type: "individual",
                country: "br",
                phone_number: username.telefone,
            }).then((res) => {
            if (res.data.message === "0") {
                alert("Erro ao carregar dados do paciente");
            } else {
                setUsuarioPagaMe(res.data[0]);
            }
        }).catch((error) => {
            console.error("Erro ao cadastrar paciente:", error);
        });
        
      }
    const getCurrentDate = (separator = '') => {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${year}${separator}${month < 10 ? `0${month}` : month}${separator}${date < 10 ? `0${date}` : date}`;
    };

    const register = () => {

        // Lógica de pagamento aqui

        history.push({
          pathname: "/solicitante",
              state: { detail: [username.cpf] },
      })
    };

    return (
        <>
            <SidebarSolicitante>
                <div className="titulo">
                    <h1>Marcar Teleconsulta</h1>
                    <br />
                    <h2>Parabéns, agendamento e pagamento efetuado com sucesso!</h2>
                </div>
                <div className="titulo">
                    <img src={Success} alt="Logo" />
                </div>
                
           <div className="div2">
            <div className="item">
            <button
                type="submit"
                className="login100-form-btn"
                style={{ alignSelf: 'center' }}
                onClick={register}
              >
               Voltar para a Home
              </button>
            </div>
           </div>
                <br />
                
            </SidebarSolicitante>
        </>
    );
};

export default ConfirmaPagamentoSolicitante;
