import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";
import styled from "styled-components";
import Axios from "axios";
import { getCurrentDate } from "../../utils/date";
import useUnasavedChangesWarning from "../../utils/hooks/useUnasavedChangesWarning";
import "./room.css";
import "../Farmaceutico/VideoChamada.css"
import { useDetectAdBlock } from "adblock-detect-react";
import { isValidCPF } from "../../utils/valida_cpf";
// https://www.npmjs.com/package/react-timer-hook
import { useStopwatch } from "react-timer-hook";
import { JitsiMeeting } from "@jitsi/react-sdk";

import { makeStyles } from "@mui/styles";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useCookies } from "react-cookie";
import { Theme } from "@mui/material";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@material-ui/core";
import PDFFile from "./pdf/PDFFile";

// https://www.tutorialspoint.com/how-to-set-cookies-in-reactjs

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
`;

const Room = (props) => {
  const [nome, setNome] = useState("");
  const [origem, setOrigem] = useState("");
  const [data_nasc, setDataNasc] = useState("");
  const [cpf, setCpf] = useState("");
  const [sexoBiologico, setSexoBiologico] = useState("");
  const [identidadeGenero, setIdentidadeGenero] = useState("");
  const [altura, setAltura] = useState("");
  const [peso, setPeso] = useState("");
  const [estado_civil, setEstCivil] = useState("");
  const [fone, setFone] = useState("");
  const [cep, setCep] = useState("");
  const [uf, setUf] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const [numeroCasa, setNumeroCasa] = useState("");
  const [possui_acompanhante, setPossuiAcomp] = useState("");
  const [nome_acompanhante, setAcompanhante] = useState("");
  const [fone_acompanhante, setFoneAcompanhante] = useState("");
  const [obs, setObs] = useState("");
  const [observacaoWhatsApp, setObservacaoWhatsApp] = useState("");
  const [medico_responsavel, setMed] = useState("");
  const [crm_medico, setCrm] = useState("");
  const [fone_medico, setFoneMedico] = useState("");
  const [grau_dependencia, setGrau] = useState("");
  const [internacao_hospitalar, setInternacao] = useState("");
  const [tempo_internacao, setTempoInternacao] = useState("");
  const [servico_emergencia, setEmergencia] = useState("");
  const [tempo_servico, setTempoEmerg] = useState("");
  const [hora_acorda, setHoraAcorda] = useState("");
  const [hora_dorme, setHoraDorme] = useState("");
  const [cafe_manha, setCafeManha] = useState("");
  const [hora_cafe, setHoraCafe] = useState("");
  const [lanche_manha, setLancheManha] = useState("");
  const [hora_lanche_manha, setHoraLancheManha] = useState("");
  const [almoco, setAlmoco] = useState("");
  const [hora_almoco, setHoraAlmoco] = useState("");
  const [lanche_tarde, setLancheTarde] = useState("");
  const [hora_lanche_tarde, setHoraLancheTarde] = useState("");
  const [janta, setJanta] = useState("");
  const [hora_janta, setHoraJanta] = useState("");
  const [ativ_fisica, setAtivFisica] = useState("");
  const [freq_ativ, setFreqAtiv] = useState("");
  const [qual_ativ, setQualAtiv] = useState("");
  const [lazer, setLazer] = useState("");
  const [problemas_saude, setProblemasSaude] = useState("");
  const [exames, setExames] = useState("");
  const [queixas, setQueixas] = useState("");
  const [alergias, setAlergias] = useState("");

  //Hepatite B, coqueluche, difteria, tétano, febre amarela, Influenza (gripe), Covid-19, HPV, Rubeola, sarampo, caxumba, pneumococica, Herpes Zóster
  const [vacinaHepatiteB, setVacinaHepatiteB] = useState(false);
  const [vacinaCoqueluche, setVacinaCoqueluche] = useState(false);
  const [vacinaDifteria, setVacinaDifteria] = useState(false);
  const [vacinaTetano, setVacinaTetano] = useState(false);
  const [vacinaFebreAmarela, setVacinaFebreAmarela] = useState(false);
  const [vacinaInfluenza, setVacinaInfluenza] = useState(false);
  const [vacinaCovid, setVacinaCovid] = useState(false);
  const [vacinaHPV, setVacinaHPV] = useState(false);
  const [vacinaRubeola, setVacinaRubeola] = useState(false);
  const [vacinaSarampo, setVacinaSarampo] = useState(false);
  const [vacinaCaxumba, setVacinaCaxumba] = useState(false);
  const [vacinaPneumococica, setVacinaPneumococica] = useState(false);
  const [vacinaHerpes, setVacinaHerpes] = useState(false);

  const [outras_obs, setOutrasObs] = useState("");
  const [resp_medicacao, setRespMedicacao] = useState("");
  const [armazena_medicacao, setArmazenaMedicacao] = useState("");
  const [controla_validade, setControlaValidade] = useState("");
  const [fuma, setFuma] = useState("");
  const [encerrramento, setEncerrramento] = useState(false);
  const [qtd_fuma, setQtdFuma] = useState("");
  const [exFumante, setExFumante] = useState("");
  const [exFumante_qtd, setExFumante_qtd] = useState("");
  const [exFumante_periodo, setExFumante_periodo] = useState("");
  const [usa_alcool, setUsaAlcool] = useState("");
  const [exEtilista, setExEtilista] = useState("0");
  const [tipo_alcool, setTipoAlcool] = useState("");
  const [qtd_alcool, setQtdAlcool] = React.useState(false);
  const [freq_alcool, setFreqAlcool] = useState("");
  const [cafe, setTomaCafe] = useState("");
  const [qtd_cafe, setQtdCafe] = useState("");
  const [cha, setTomaCha] = useState("");
  const [qual_cha, setQualCha] = useState("");
  const [qtd_cha, setQtdCha] = useState("");
  const [freq_cha, setFreqCha] = useState("");
  const [dependencia_quimica, setDependQuimica] = useState("");
  const [qual_dependencia, setQualDep] = useState("");
  const [chimarrao, setChimarrao] = useState("");
  const [qtd_chimarrao, setQtdChimarrao] = useState("");
  const [freq_chimarrao, setFreqChimarrao] = useState("");
  const [situacao_tratamento, setSituacaoTratamento] = useState("");
  const [motivo_doenca, setMotivoDoenca] = useState("");
  const [id, setId] = useState(props.match.params.roomID);
  const [listConduta, setListaConduta] = useState([]);
  const [listaOutrasInformacoes, setListaOutrasInformacoes] = useState([]);
  const [listaNutricao, setListaNutricao] = useState([]);
  const [listaPsicologia, setListaPsicologia] = useState([]);
  const [listaFarmaceutico, setListaFarmaceutico] = useState([]);
  const [conduta, setConduta] = useState("");
  const [condutaID, setCondutaID] = useState("");
  const [dataConduta, setDataConduta] = useState("");
  const [responsavelConduta, setResponsavelConduta] = useState("");
  const [observacaoConduta, setObservacaoConduta] = useState("");
  const [outrasInformacoes, setOutrasInformacoes] = useState("");
  const [tipoUser, setTipoUser] = useState("10");
  const [mostrarForm, setMostrarForm] = useState(false);
  const [mostrarUsuario, setMostrarUsuario] = useState(false);
  const [medicamento, setMedicamento] = useState("");
  const [medicamentoID, setMedicamentoID] = useState("");
  const [dose, setDose] = useState("");
  const [posologia, setPosologia] = useState("4/4h");
  const [tempoUso, setTempoUso] = useState("7 dias");
  const [momentoMedicamento, setMomentoMedicamento] = useState("antes");
  const [mesmoLab, setMesmoLaboratorio] = useState("Sim");
  const [mesmoHorario, setMesmoHorario] = useState("Sim");
  const [motivoUso, setMotivoUso] = useState("");
  const [quemIndicou, setQuemIndicou] = useState("");
  const [necessidade, setNecessidade] = useState("");
  const [efetividade, setEfetividade] = useState("");
  const [observacaoMedicamento, setObservacaoMedicamento] = useState("");
  const [informacaoNutricao, setInformacaoNutricao] = useState("");
  const [informacaoPsicologia, setInformacaoPsicologia] = useState("");
  const [informacaoFarmaceutico, setInformacaoFarmaceutico] = useState("");
  const [seguro, setSeguro] = useState("");
  const [isFarmaceutico, setIsFarmaceutico] = useState("");
  const [codigo_crf, setCodigoCRF] = useState("");
  const [codigo_crf_uf, setCodigoCRFUF] = useState("");
  const [listaMedicamentos, setListaMedicamentos] = useState([]);

  const [adesaoMorisky, setAdesaoMorisky] = useState("");
  const [razaoAdesaoMorisky, setRazaoAdesaoMorisky] = useState("");
  const [razaoAdesaoMoriskyOutro, setRazaoAdesaoMoriskyOutro] = useState("");

  const [descarteMedicamento, setDescarteMedicamento] = useState("");
  const [descarteMedicamentoOutro, setDescarteMedicamentoOutro] = useState("");

  const [medicamentosSemEfetividade, setMedicamentosSemEfetividade] =
    useState("");
  const [medicamentosEfeitosAdversos, setMedicamentosEfeitosAdversos] =
    useState("");

  const [dataEfeitoAdverso, setDataEfeitoAdverso] = useState("");
  const [dataPrimeiraDose, setDataPrimeiraDose] = useState("");
  const [efeitoAdverso, setEfeitoAdverso] = useState("");
  const [efeitoAdversoMedicamento, setEfeitoAdversoMedicamento] = useState("");
  const [listaEfeitosAdversos, setListaEfeitosAdversos] = useState([]);
  const [efeitoAdversoID, setEfeitoAdversoID] = useState("");

  // interações de botões de atualização
  const [isAddConduta, setIsAddConduta] = useState(true);
  const [isEditConduta, setIsEditConduta] = useState(false);
  const [isAddEfeitoAdverso, setIsAddEfeitoAdverso] = useState(true);
  const [isEditEfeitoAdverso, setIsEditEfeitoAdverso] = useState(false);
  const [isAddCampoFarmaceutico, setIsAddCampoFarmaceutico] = useState(true);
  const [isAddCampoNutricao, setIsAddCampoNutricao] = useState(true);
  const [isAddCampoPsicologia, setIsAddCampoPsicologia] = useState(true);
  const [isAddMedicamento, setIsAddMedicamento] = useState(true);

  const [isEditCampoFarmaceutico, setIsEditCampoFarmaceutico] = useState(false);
  const [isEditCampoNutricao, setIsEditCampoNutricao] = useState(false);
  const [isEditCampoPsicologia, setIsEditCampoPsicologia] = useState(false);
  const [isEditMedicamento, setIsEditMedicamento] = useState(false);

  const [campoExclusivoFarmaceuticoID, setCampoExclusivoFarmaceuticoID] =
    useState("");
  const [campoExclusivoNutricaoID, setCampoExclusivoNutricaoID] = useState("");
  const [campoExclusivoPsicologiaID, setCampoExclusivoPsicologiaID] =
    useState("");

  const [mostrarVideo, setMostrarVideo] = useState(false);

  const [isPaciente, setIsPaciente] = useState();

  const [peers, setPeers] = useState([]);
  const roomID = props.match.params.roomID;

  const [usuario, setUsuario] = useState([]);

  const adBlockDetected = useDetectAdBlock();

  // Cookies
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  const [Prompt, setShowWarning, setPristine] = useUnasavedChangesWarning();

  Axios.defaults.withCredentials = true;

  const stopwatchOffset = new Date();
  if (cookies.cronometro != undefined) {
    stopwatchOffset.setSeconds(
      stopwatchOffset.getSeconds() + Number(cookies.cronometro)
    );
  } else {
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds());
  }
  const { seconds, minutes, hours, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false, offsetTimestamp: stopwatchOffset });

  const [openDialogPDF, setOpenDialogPDF] = React.useState(false);
  const handleClickOpenDialogPDF = () => {
    setCodigoPDF(Math.floor(Math.random() * (99999 - 11111 + 1)) + 11111);
    setOpenDialogPDF(true);
  };
  const handleCloseDialogPDF = () => {
    setOpenDialogPDF(false);
  };

  const [openDialogLacuna, setOpenDialogLacuna] = useState(false);
  const [codigoPDF, setCodigoPDF] = useState("");


  //video specs:
  const [meetingUrl, setMeetingUrl] = useState("");
  const [videoError, setVideoError] = useState(null);
  var dateNow = new Date();
  var currentTime = new Date(dateNow.toISOString());
  const [nomeSala, setnomeSala] = useState('farmaceuticoNome-pacientenome-');
  // -------------------------------------------------------------


  const handleClickOpenDialogLacuna = () => {
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/criaPDFLacuna", {
      nome: nome,
      cpf: cpf,
      responsavel: usuario.nome,
      evolucao: conduta,
      crf: codigo_crf,
      crfUf: codigo_crf_uf,
      codigo: codigoPDF,
    }).then((response) => {
      if (response.status === 200) {
        console.log("PDF criado com sucesso!");
        setOpenDialogLacuna(true);
      } else {
        console.error("Erro ao criar o PDF.");
        alert("Erro ao criar o PDF.");
      }
    });
  };
  const handleCloseDialogLacuna = () => {
    setOpenDialogLacuna(false);
  };

  useEffect(() => {
    Axios.defaults.withCredentials = true;
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/login", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setUsuario(response.data.user[0]);
        setResponsavelConduta(usuario.nome);
        setMostrarForm(true);
        setMostrarUsuario(true);
        if (response.data.user[0].tipo_usuario == "1") {
          setIsFarmaceutico(true);
          Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/returnCRF", {
            id: response.data.user[0].cpf,
          }).then((response) => {
            setCodigoCRF(response.data[0].registro);
            setCodigoCRFUF(response.data[0].uf);
          });
        } else {
          setIsFarmaceutico(true);
        }
      } else {
        setTipoUser("0");
        setIsPaciente(true);
      }
    });
  }, []);

  const retornaCondutas = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaConduta", {
      id: props.match.params.roomID,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaConduta(response.data);
      }
    });
  };

  const retornaEfeitosAdversos = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaEfeitosAdversos", {
      id: props.match.params.roomID,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaEfeitosAdversos(response.data);
      }
    });
  };

  const retornaOutrasInformacoes = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaOutrasInformacoes", {
      id: props.match.params.roomID,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaOutrasInformacoes(response.data);
      }
    });
  };

  const retornaCampoNutricao = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaCampoNutricao", {
      id: props.match.params.roomID,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaNutricao(response.data);
      }
    });
  };

  const retornaCampoPsicologia = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaCampoPsicologia", {
      id: props.match.params.roomID,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaPsicologia(response.data);
      }
    });
  };

  const retornaCampoFarmaceutico = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaCampoFarmaceutico", {
      id: props.match.params.roomID,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response);
        setListaFarmaceutico(response.data);
      }
    });
  };


  const retornaMedicamentos = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaMedicamentos", {
      id: props.match.params.roomID,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        console.log(response)
        setListaMedicamentos(response.data);
      }
    });
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (year < "2021") {
      return "";
    } else {
      if (month.length < 2) {
        month = "0" + month;
      }
      if (day.length < 2) {
        day = "0" + day;
      }

      return [day, month, year].join("/");
    }
  }

  async function salvaForm() {
    let vacinas = await checkVacinas();
    if (
      informacaoFarmaceutico == "" &&
      informacaoNutricao == "" &&
      informacaoPsicologia == "" &&
      conduta == "" &&
      outrasInformacoes == "" &&
      observacaoConduta == ""
    ) {
      if (
        data_nasc != "" &&
        cpf != "" &&
        nome != "" &&
        fone != "" &&
        identidadeGenero != "" &&
        sexoBiologico != ""
      ) {
        if (isValidCPF(cpf)) {
          Axios.defaults.withCredentials = false;
          pause();
          let duracao = hours + ":" + minutes + ":" + seconds;
          Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/salvaPront", {
            nome: nome,
            origem: origem,
            data_nasc: data_nasc,
            cpf: cpf,
            sexo_biologico: sexoBiologico,
            identidade_genero: identidadeGenero,
            altura: altura,
            peso: peso,
            estado_civil: estado_civil,
            fone: fone,
            cep: cep,
            uf: uf,
            cidade: cidade,
            bairro: bairro,
            rua: rua,
            numeroCasa: numeroCasa,
            possui_acompanhante: possui_acompanhante,
            nome_acompanhante: nome_acompanhante,
            fone_acompanhante: fone_acompanhante,
            obs: obs,
            observacaoWhatsApp: observacaoWhatsApp,
            medico_responsavel: medico_responsavel,
            crm_medico: crm_medico,
            fone_medico: fone_medico,
            grau_dependencia: grau_dependencia,
            internacao_hospitalar: internacao_hospitalar,
            tempo_internacao: tempo_internacao,
            servico_emergencia: servico_emergencia,
            tempo_servico: tempo_servico,
            hora_acorda: hora_acorda,
            hora_dorme: hora_dorme,
            cafe_manha: cafe_manha,
            hora_cafe: hora_cafe,
            lanche_manha: lanche_manha,
            hora_lanche_manha: hora_lanche_manha,
            almoco: almoco,
            hora_almoco: hora_almoco,
            lanche_tarde: lanche_tarde,
            hora_lanche_tarde: hora_lanche_tarde,
            janta: janta,
            hora_janta: hora_janta,
            ativ_fisica: ativ_fisica,
            freq_ativ: freq_ativ,
            qual_ativ: qual_ativ,
            lazer: lazer,
            problemas_saude: problemas_saude,
            exames: exames,
            queixas: queixas,
            alergias: alergias,
            vacinas: vacinas.toString(),
            outras_obs: outras_obs,
            resp_medicacao: resp_medicacao,
            armazena_medicacao: armazena_medicacao,
            controla_validade: controla_validade,
            fuma: fuma,
            qtd_fuma: qtd_fuma,
            ex_fumante: exFumante,
            ex_fumante_qtd: exFumante_qtd,
            ex_fumante_periodo: exFumante_periodo,
            usa_alcool: usa_alcool,
            exEtilista: exEtilista,
            tipo_alcool: tipo_alcool,
            qtd_alcool: qtd_alcool,
            freq_alcool: freq_alcool,
            cafe: cafe,
            qtd_cafe: qtd_cafe,
            cha: cha,
            qual_cha: qual_cha,
            qtd_cha: qtd_cha,
            freq_cha: freq_cha,
            dependencia_quimica: dependencia_quimica,
            qual_dependencia: qual_dependencia,
            chimarrao: chimarrao,
            qtd_chimarrao: qtd_chimarrao,
            freq_chimarrao: freq_chimarrao,
            situacao_tratamento: situacao_tratamento,
            motivo_doenca: motivo_doenca,
            id: id,
            medicamentosEfeitosAdversos: medicamentosEfeitosAdversos,
            medicamentosSemEfetividade: medicamentosSemEfetividade,
            adesaoMorisky: adesaoMorisky,
            razaoAdesaoMorisky: razaoAdesaoMorisky,
            razaoAdesaoMoriskyOutro: razaoAdesaoMoriskyOutro,
            descarteMedicamento: descarteMedicamento,
            descarteMedicamentoOutro: descarteMedicamentoOutro,
          }).then((response) => {
            if (response.data.message == "1") {
              if (minutes != 0 && seconds != 0) {
                Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/adicionaDuracaoAtendimento", {
                  duracao: duracao,
                  id: id,
                }).then((response) => {
                  if (response.data.message == "1") {
                    alert("Prontuário salvo com sucesso!");
                    removeCookie("cronometro", { path: "/" });
                  } else {
                    alert("Erro ao salvar formulário!");
                  }
                });
              } else {
                removeCookie("cronometro", { path: "/" });
                alert("Prontuário salvo com sucesso!");
              }
              setPristine();
            } else {
              alert("Erro ao salvar formulário!");
            }
          });
        } else {
          alert("CPF inválido");
        }
      } else {
        alert(
          "Preencha os campos obrigatórios! (Data de nascimento, CPF, Nome, Telefone, Sexo e Identidade de Gênero)"
        );
      }
    } else {
      alert("Existem campos não salvos!");
    }
  }

  const salvaConduta = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/salvaConduta", {
      id: id,
      data: getCurrentDate("-"),
      conduta: conduta,
      //observacao: observacaoConduta,
      responsavel: usuario.nome,
    }).then((response) => {
      if (response.data.message == "1") {
        retornaCondutas();
        setPristine();
        retornaCondutas();
        //setConduta(""); - Limpa campo de conduta
        setObservacaoConduta("");
        retornaCondutas();
      } else {
        alert("Erro ao salvar conduta!");
      }
    });

    if (encerrramento == true) {
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/encerraTeleAssistencia", {
        id: id,
      }).then((response) => {
        if (response.data.message == "1") {
          console.log("Teleconsulta encerrada!");
        } else {
          console.log("Erro ao encerrar Teleconsulta!");
        }
      });
    }
  };

  const alteraConduta = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/alteraConduta", {
      id: condutaID,
      data: getCurrentDate("-"),
      conduta: conduta,
      observacao: observacaoConduta,
      responsavel: usuario.nome,
    }).then((response) => {
      if (response.data.message == "1") {
        retornaCondutas();
        setConduta("");
        setObservacaoConduta("");
        retornaCondutas();
        setIsEditConduta(false);
        setIsAddConduta(true);
      } else {
        alert("Erro ao atualizar conduta!");
      }
    });
  };

  const alteraEfeitoAdverso = () => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/alteraEfeitoAdverso", {
      id: efeitoAdversoID,
      data: dataEfeitoAdverso,
      texto: efeitoAdverso,
      medicamento: efeitoAdversoMedicamento,
    }).then((response) => {
      if (response.data.message == "1") {
        setPristine();
        setEfeitoAdverso("");
        setDataEfeitoAdverso(null);
        setEfeitoAdversoMedicamento("");
        retornaEfeitosAdversos();
        limpaCampoEfeitoAdverso();
      } else {
        alert("Erro ao atualizar efeito adverso!");
      }
    });
  };

  const salvaEfeitoAdverso = () => {
    Axios.defaults.withCredentials = false;
    if (dataEfeitoAdverso != "" && efeitoAdverso != "") {
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/salvaEfeitoAdverso", {
        id: id,
        data: dataEfeitoAdverso,
        texto: efeitoAdverso,
        medicamento: efeitoAdversoMedicamento,
      }).then((response) => {
        if (response.data.message == "1") {
          setPristine();
          setEfeitoAdverso("");
          setEfeitoAdversoMedicamento("");
          setDataEfeitoAdverso("");
          retornaEfeitosAdversos();
        } else {
          alert("Erro ao salvar efeito adverso!");
        }
      });
    } else {
      alert("Preencha todos os campos!");
    }
  };

  const atualizaCampoNutricao = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/alteraCampoNutricao", {
      id: campoExclusivoNutricaoID,
      data: getCurrentDate("-"),
      informacao: informacaoNutricao,
      responsavel: usuario.nome,
    }).then((response) => {
      if (response.data.message == "0") {
        alert("Erro ao salvar informação!");
      } else {
        alert("Informação salva com sucesso!");
        retornaCampoNutricao();
        setInformacaoNutricao("");
        setIsEditCampoNutricao(false);
        setIsAddCampoNutricao(true);
      }
    });
  };

  const atualizaCampoPsicologia = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/alteraCampoPsicologia", {
      id: campoExclusivoPsicologiaID,
      data: getCurrentDate("-"),
      informacao: informacaoPsicologia,
      responsavel: usuario.nome,
    }).then((response) => {
      if (response.data.message == "0") {
        alert("Erro ao salvar informação!");
      } else {
        alert("Informação salva com sucesso!");
        retornaCampoPsicologia();
        setInformacaoPsicologia("");
        setIsEditCampoPsicologia(false);
        setIsAddCampoPsicologia(true);
      }
    });
  };

  const atualizaCampoFarmaceutico = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/alteraCampoFarmaceutico", {
      id: campoExclusivoFarmaceuticoID,
      data: getCurrentDate("-"),
      informacao: informacaoFarmaceutico,
      responsavel: usuario.nome,
    }).then((response) => {
      if (response.data.message == "0") {
        alert("Erro ao salvar informação!");
      } else {
        alert("Informação salva com sucesso!");
        retornaCampoFarmaceutico();
        setInformacaoFarmaceutico("");
        setIsEditCampoFarmaceutico(false);
        setIsAddCampoFarmaceutico(true);
      }
    });
  };

  const salvaOutrasInformacoes = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/salvaOutrasInformacoes", {
      id: id,
      data: getCurrentDate("-"),
      informacao: outrasInformacoes,
      responsavel: usuario.nome,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
        retornaOutrasInformacoes();
      }
      setOutrasInformacoes("");
      retornaOutrasInformacoes();
    });
  };

  const salvaInformacaoNutricao = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/salvaNutricao", {
      id: id,
      data: getCurrentDate("-"),
      informacao: informacaoNutricao,
      responsavel: usuario.nome,
      cpf: usuario.cpf,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
        retornaCampoNutricao();
      }
      setInformacaoNutricao("");
      retornaCampoNutricao();
      setPristine();
    });
  };

  const salvaInformacaoPsicologia = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/salvaPsicologia", {
      id: id,
      data: getCurrentDate("-"),
      informacao: informacaoPsicologia,
      responsavel: usuario.nome,
      cpf: usuario.cpf,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
        retornaCampoPsicologia();
      }
      setInformacaoPsicologia("");
      retornaCampoPsicologia();
      setPristine();
    });
  };

  const salvaInformacaoFarmaceutico = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/salvaFarmaceutico", {      
      id: id,
      data: getCurrentDate("-"),
      informacao: informacaoFarmaceutico,
      responsavel: usuario.nome,
      cpf: usuario.cpf,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
        retornaCampoFarmaceutico();
      }
      setInformacaoFarmaceutico("");

      retornaCampoFarmaceutico();
      setPristine();
    });
  };

  const excluirMed = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/excluirMed", {
      id: id,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        alert("Você excluiu o medicamento ID: " + id);
        retornaMedicamentos();
      }
    });
  };

  const consultaMedicamento = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaMedicamento", {
      id: id,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        setMedicamentoID(response.data[0].id);
        setMedicamento(response.data[0].medicamento);
        setDose(response.data[0].dose);
        setPosologia(response.data[0].posologia);
        setTempoUso(response.data[0].tempo_uso);
        setMesmoLaboratorio(response.data[0].mesmo_laboratorio);
        setMesmoHorario(response.data[0].mesmo_horario);
        setMotivoUso(response.data[0].motivo_uso);
        setQuemIndicou(response.data[0].quem_indicou);
        setNecessidade(response.data[0].necessario);
        setEfetividade(response.data[0].efetividade);
        setSeguro(response.data[0].seguranca);
        setIsEditMedicamento(true);
        setIsAddMedicamento(false);
      }
    });
  };

  const consultaConduta = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaConduta", { id: id }).then(
      (response) => {
        if (response.data.aviso) {
          alert(response.data.aviso);
        } else {
          setCondutaID(response.data[0].id);
          setConduta(response.data[0].conduta);
          setObservacaoConduta(response.data[0].observacao);
          setIsEditConduta(true);
          setIsAddConduta(false);
        }
      }
    );
  };

  const consultaEfeitoAdverso = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaEfeitoAdverso", {
      id: id,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        setEfeitoAdversoID(response.data[0].id);
        setEfeitoAdverso(response.data[0].efeito);
        setEfeitoAdversoMedicamento(response.data[0].medicamento);
        let date = new Date(response.data[0].data);
        setDataEfeitoAdverso(date.toISOString().split("T")[0]);
        setIsEditEfeitoAdverso(true);
        setIsAddEfeitoAdverso(false);
      }
    });
  };

  const consultaCampoExclusivoNutricao = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaCampoExclusivoNutricao", {
      id: id,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        setCampoExclusivoNutricaoID(id);
        setInformacaoNutricao(response.data[0].informacao);
        setIsEditCampoNutricao(true);
        setIsAddCampoNutricao(false);
      }
    });
  };

  const consultaCampoExclusivoPsicologia = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaCampoExclusivoPsicologia", {
      id: id,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        setCampoExclusivoPsicologiaID(id);
        setInformacaoPsicologia(response.data[0].informacao);
        setIsEditCampoPsicologia(true);
        setIsAddCampoPsicologia(false);
      }
    });
  };

  const consultaCampoExclusivoFarmaceutico = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaCampoExclusivoFarmaceutico", {
      id: id,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        setCampoExclusivoFarmaceuticoID(id);
        setInformacaoFarmaceutico(response.data[0].informacao);
        setIsEditCampoFarmaceutico(true);
        setIsAddCampoFarmaceutico(false);
      }
    });
  };

  const excluirConduta = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/excluirConduta", { id: id }).then(
      (response) => {
        if (response.data.aviso) {
          alert(response.data.aviso);
        } else {
          alert("Você excluiu a conduta ID: " + id);
          retornaCondutas();
        }
      }
    );
  };

  const excluirEfeitoAdverso = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/excluirEfeitoAdverso", {
      id: id,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        alert("Efeito adverso excluído com sucesso!");
        retornaEfeitosAdversos();
      }
    });
  };

  // Necessário atualizando do server para prosseguir
  const excluirOutrasInformacoes = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/excluirOutrasInformacoes", {
      id: id,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        alert("Você excluiu a Informação com ID: " + id);
        retornaOutrasInformacoes();
      }
    });
  };

  const excluirNutricao = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/excluirNutricao", { id: id }).then(
      (response) => {
        if (response.data.aviso) {
          alert(response.data.aviso);
        } else {
          alert("Você excluiu a Informação Nutricional com ID: " + id);
          retornaCampoNutricao();
        }
      }
    );
  };

  const excluirPsicologia = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/excluirPsicologia", { id: id }).then(
      (response) => {
        if (response.data.aviso) {
          alert(response.data.aviso);
        } else {
          alert("Você excluiu a Informação de Psicologia com ID: " + id);
          retornaCampoPsicologia();
        }
      }
    );
  };

  const excluirFarmaceutico = (id) => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/excluirFarmaceutico", {
      id: id,
    }).then((response) => {
      if (response.data.aviso) {
        alert(response.data.aviso);
      } else {
        alert("Você excluiu a Informação de Psicologia com ID: " + id);
        retornaCampoFarmaceutico();
      }
    });
  };

  const salvaMedicamento = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/salvaMedicamento", {
      id: id,
      medicamento: medicamento,
      dose: dose,
      posologia: posologia,
      tempoUso: tempoUso,
      momentoMedicamento: momentoMedicamento.toLowerCase(),
      mesmoLab: mesmoLab,
      mesmoHorario: mesmoHorario,
      motivoUso: motivoUso,
      quemIndicou: quemIndicou,
      necessidade: necessidade,
      efetividade: efetividade,
      observacao: observacaoMedicamento,
      seguro: seguro,
      dataPrimeiraDose: dataPrimeiraDose,
      data: getCurrentDate("-"),
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
        retornaMedicamentos();
      }
      setMedicamento(" ");
      setDose(" ");
      setPosologia("4/4h");
      setTempoUso("7 dias");
      setMomentoMedicamento("Antes");
      setMesmoLaboratorio("Sim");
      setMesmoHorario("Sim");
      setObservacaoMedicamento("");
      setMotivoUso(" ");
      setQuemIndicou(" ");
      setNecessidade(" ");
      setEfetividade(" ");
      setSeguro(" ");
      retornaMedicamentos();
      setDataPrimeiraDose(" ")
    });
  };

  const alteraMedicamento = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/alteraMedicamento", {
      id_medicamento: medicamentoID,
      medicamento: medicamento,
      dose: dose,
      posologia: posologia,
      tempoUso: tempoUso,
      momentoMedicamento: momentoMedicamento,
      mesmoLab: mesmoLab,
      mesmoHorario: mesmoHorario,
      motivoUso: motivoUso,
      quemIndicou: quemIndicou,
      necessidade: necessidade,
      efetividade: efetividade,
      seguro: seguro,
      data: getCurrentDate("-"),
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
        retornaMedicamentos();
      }
      setMedicamento(" ");
      setDose(" ");
      setPosologia("4/4h");
      setTempoUso("7 dias");
      setMomentoMedicamento("Antes")
      setMesmoLaboratorio("Sim");
      setMesmoHorario("Sim");
      setMotivoUso(" ");
      setQuemIndicou(" ");
      setNecessidade(" ");
      setEfetividade(" ");
      setSeguro(" ");
      setIsAddMedicamento(true);
      setIsEditMedicamento(false);
      retornaMedicamentos();
    });
  };
  // Interação responsável por salvar o Disclaimer

  const limpaCamposMedicamentos = () => {
    setMedicamento(" ");
    setDose(" ");
    setPosologia("4/4h");
    setTempoUso("7 dias");
    setMomentoMedicamento("Antes")
    setMesmoLaboratorio("Sim");
    setMesmoHorario("Sim");
    setMotivoUso(" ");
    setQuemIndicou(" ");
    setNecessidade(" ");
    setEfetividade(" ");
    setSeguro(" ");
    retornaMedicamentos();
    setIsAddMedicamento(true);
    setIsEditMedicamento(false);
  };

  function limpaVacinas() {
    setVacinaHepatiteB(false);
    setVacinaCoqueluche(false);
    setVacinaFebreAmarela(false);
    setVacinaInfluenza(false);
    setVacinaDifteria(false);
    setVacinaTetano(false);
    setVacinaCovid(false);
    setVacinaHPV(false);
    setVacinaPneumococica(false);
    setVacinaRubeola(false);
    setVacinaHerpes(false);
    setVacinaSarampo(false);
    setVacinaCaxumba(false);
  }

  function checkVacinas() {
    let aux = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (vacinaHepatiteB) {
      aux[0] = 1;
    }
    if (vacinaCoqueluche) {
      aux[1] = 1;
    }
    if (vacinaDifteria) {
      aux[2] = 1;
    }
    if (vacinaTetano) {
      aux[3] = 1;
    }
    if (vacinaFebreAmarela) {
      aux[4] = 1;
    }
    if (vacinaInfluenza) {
      aux[5] = 1;
    }
    if (vacinaCovid) {
      aux[6] = 1;
    }
    if (vacinaHPV) {
      aux[7] = 1;
    }
    if (vacinaRubeola) {
      aux[8] = 1;
    }
    if (vacinaSarampo) {
      aux[9] = 1;
    }
    if (vacinaCaxumba) {
      aux[10] = 1;
    }
    if (vacinaPneumococica) {
      aux[11] = 1;
    }
    if (vacinaHerpes) {
      aux[12] = 1;
    }
    return aux.toString();
  }

  const limpaCampoCondutas = () => {
    setConduta("");
    setObservacaoConduta("");
    setIsEditConduta(false);
    setIsAddConduta(true);
    setEncerrramento(false);
  };

  const limpaCampoEfeitoAdverso = () => {
    setEfeitoAdverso("");
    setDataEfeitoAdverso("");
    setIsEditEfeitoAdverso(false);
    setIsAddEfeitoAdverso(true);
  };

  const limpaCampoExclusivoNutricao = () => {
    setInformacaoNutricao("");
    setIsEditCampoNutricao(false);
    setIsAddCampoNutricao(true);
  };

  const limpaCampoExclusivoPsicologia = () => {
    setInformacaoPsicologia("");
    setIsEditCampoPsicologia(false);
    setIsAddCampoPsicologia(true);
  };

  const limpaCampoExclusivoFarmaceutico = () => {
    setInformacaoFarmaceutico("");
    setIsEditCampoFarmaceutico(false);
    setIsAddCampoFarmaceutico(true);
  };

  const iniciarCronometro = () => {
    if (!isRunning) {
      start();
    }
  };

  const pausarCronometro = () => {
    pause();
    let time;
    time = minutes * 60;
    time = time + seconds;
    let path = "/room/" + roomID;
    setCookie("cronometro", time, { path: path });
  };

  const resetCronometro = () => {
    reset();
    //pause();
    let path = "/room/" + roomID;
    removeCookie("cronometro", { path: path });
  };

  const preencheCEP = () => {
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/preencheCEP", {
      headers: {
        "Access-Allow-Control-Origin": "http://localhost:3000",
      },
      cep: cep,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setCep(response.data.cep);
        setBairro(response.data.bairro);
        setRua(response.data.logradouro);
        setCidade(response.data.localidade);
        setUf(response.data.uf);
      }
    });
  };

  // permissões de interface
  useEffect(() => {
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/login", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setUsuario(response.data.user[0]);
        setResponsavelConduta(usuario.nome);
        if (response.data.user[0].tipo_usuario == "1" || "4") {
          setTipoUser("1");
          setMostrarForm(true);
          setMostrarUsuario(true);
          setMostrarVideo(true);
        } else {
          setTipoUser("0");
        }
      } else {
        setTipoUser("0");
      }
    });

    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/carregaPront", {
      id: id,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setNome(response.data[0].nome);
        setOrigem(response.data[0].origem);
        if (response.data[0].data_nasc != null) {
          let date = new Date(response.data[0].data_nasc);
          setDataNasc(date.toISOString().split("T")[0]);
        }
        setCpf(response.data[0].paciente_cpf);
        if (response.data[0].sexo_biologico != "") {
          setSexoBiologico(response.data[0].sexo_biologico);
        }
        if (response.data[0].identidade_genero != "") {
          setIdentidadeGenero(response.data[0].identidade_genero);
        }
        setAltura(response.data[0].altura);
        setPeso(response.data[0].peso);
        setEstCivil(response.data[0].estado_civil);
        setFone(response.data[0].fone);
        setCep(response.data[0].cep);
        setUf(response.data[0].estado);
        setCidade(response.data[0].cidade);
        setBairro(response.data[0].bairro);
        setRua(response.data[0].rua);
        setNumeroCasa(response.data[0].numero_casa);
        setPossuiAcomp(response.data[0].possui_acompanhante);
        setAcompanhante(response.data[0].nome_acompanhante);
        setFoneAcompanhante(response.data[0].telefone_acompanhamento);
        setObs(response.data[0].observacao_um);
        setMed(response.data[0].medico_responsavel);
        setCrm(response.data[0].crm_medico);
        setFoneMedico(response.data[0].telefone_medico);
        setGrau(response.data[0].grau_dependencia);
        setInternacao(response.data[0].internacao_hospital);
        setTempoInternacao(response.data[0].periodo_internacao);
        setEmergencia(response.data[0].servico_emergencia);
        setTempoEmerg(response.data[0].periodo_servemerg);
        setHoraAcorda(response.data[0].horario_acorda);
        setHoraDorme(response.data[0].horario_dorme);
        setCafeManha(response.data[0].cafe_manha);
        setHoraCafe(response.data[0].horario_cafe);
        setLancheManha(response.data[0].lanche_um);
        setHoraLancheManha(response.data[0].horario_lancheum);
        setAlmoco(response.data[0].almoco);
        setHoraAlmoco(response.data[0].horario_almoco);
        setLancheTarde(response.data[0].lanche_dois);
        setHoraLancheTarde(response.data[0].horario_lanchedois);
        setJanta(response.data[0].janta);
        setHoraJanta(response.data[0].horario_janta);
        setAtivFisica(response.data[0].atividade_fisica);
        setFreqAtiv(response.data[0].freq_atv_fisica);
        setQualAtiv(response.data[0].qual_atv);
        setLazer(response.data[0].lazer);
        setProblemasSaude(response.data[0].problemas_saude);
        setExames(response.data[0].exames);
        setQueixas(response.data[0].queixas);
        setAlergias(response.data[0].alergias);
        if (response.data[0].vacinas != null) {
          let vacinasArray = response.data[0].vacinas.split(",");
          if (vacinasArray[0] == "1") {
            setVacinaHepatiteB(true);
          }
          if (vacinasArray[1] == "1") {
            setVacinaCoqueluche(true);
          }
          if (vacinasArray[2] == "1") {
            setVacinaDifteria(true);
          }
          if (vacinasArray[3] == "1") {
            setVacinaTetano(true);
          }
          if (vacinasArray[4] == "1") {
            setVacinaFebreAmarela(true);
          }
          if (vacinasArray[5] == "1") {
            setVacinaInfluenza(true);
          }
          if (vacinasArray[6] == "1") {
            setVacinaCovid(true);
          }
          if (vacinasArray[7] == "1") {
            setVacinaHPV(true);
          }
          if (vacinasArray[8] == "1") {
            setVacinaRubeola(true);
          }
          if (vacinasArray[9] == "1") {
            setVacinaSarampo(true);
          }
          if (vacinasArray[10] == "1") {
            setVacinaCaxumba(true);
          }
          if (vacinasArray[11] == "1") {
            setVacinaPneumococica(true);
          }
          if (vacinasArray[12] == "1") {
            setVacinaHerpes(true);
          }
        }
        setOutrasObs(response.data[0].outras_obs);
        setRespMedicacao(response.data[0].responsavel_adm);
        setArmazenaMedicacao(response.data[0].onde_armazena);
        setControlaValidade(response.data[0].controla_validade);
        setFuma(response.data[0].fuma);
        setQtdFuma(response.data[0].qtd_fuma);
        setExFumante(response.data[0].ex_fumante);
        setExFumante_qtd(response.data[0].ex_fumante_qtd_dia);
        setExFumante_periodo(response.data[0].ex_fumante_periodo);
        setUsaAlcool(response.data[0].alcool);
        setTipoAlcool(response.data[0].tipo_alcool);
        setQtdAlcool(response.data[0].qtd_alcool);
        setFreqAlcool(response.data[0].freq_alcool);
        setExEtilista(response.data[0].ex_etilista);
        setTomaCafe(response.data[0].cafe);
        setQtdCafe(response.data[0].qtd_cafe);
        setTomaCha(response.data[0].cha);
        setQualCha(response.data[0].qual_cha);
        setQtdCha(response.data[0].qtd_cha);
        setFreqCha(response.data[0].freq_cha);
        setDependQuimica(response.data[0].possui_dependencia);
        setQualDep(response.data[0].qual_dependencia);
        setChimarrao(response.data[0].chimarrao);
        setQtdChimarrao(response.data[0].qtd_chimarrao);
        setFreqChimarrao(response.data[0].freq_chimarrao);
        setSituacaoTratamento(response.data[0].situacao_tratamento);
        setMotivoDoenca(response.data[0].motivo_doenca_relatado);
        setObservacaoWhatsApp(response.data[0].observacao_whatsapp);
        setMedicamentosEfeitosAdversos(
          response.data[0].medicamentos_efeitos_adversos
        );
        setMedicamentosSemEfetividade(
          response.data[0].medicamentos_sem_efetividade
        );
        setAdesaoMorisky(response.data[0].adesao_morisky);
        setRazaoAdesaoMorisky(response.data[0].razao_adesao_morisky);
        setRazaoAdesaoMoriskyOutro(response.data[0].razao_adesao_morisky_outro);
        setDescarteMedicamento(response.data[0].descarte_medicamento);
        setDescarteMedicamentoOutro(
          response.data[0].descarte_medicamento_outro
        );
      }
      console.log(response);
    });

    retornaCondutas();
    retornaOutrasInformacoes();
    retornaMedicamentos();
    retornaCampoNutricao();
    retornaCampoPsicologia();
    retornaCampoFarmaceutico();
    retornaEfeitosAdversos();
  }, []);


  useEffect(() => {
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/create-meeting", {
      headers: {
        "Access-Control-Allow-Origin": "http://localhost:3000",
      },
    }).then((response) => {
      setMeetingUrl(response.data);
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/atualizaLinkAtendimento", {
        pront_id: id,
        link_paciente: response.data.roomUrl
      }).then((response) => {
        console.log("Link de sala registrado")
      }).catch((error) => {
        console.log("Erro ao registrar link da sala")
      })
    }).catch((error) => {
        alert(error)
        setVideoError("Erro ao criar reuniao.");
      });
  }, []);

  if (videoError) {
    return <p>{videoError}</p>;
  }

  function mostrarVideoAtendente() {
    if (mostrarVideo && mostrarForm) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="div_body">
      {openDialogPDF && (
        <Dialog
          open={openDialogPDF}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              handleCloseDialogLacuna();
            }
          }}
          maxWidth="lg"
          fullHeight
        >
          <DialogTitle>
            Visualização prévia - finalização do atendimento
          </DialogTitle>
          <DialogContent style={{ height: "900px", width: "900px" }}>
            <PDFViewer
              className="pdf-preview"
              showToolbar={false}
              style={{
                borderRadius: 5,
                border: "none",
                width: "100%",
                height: "calc(100vh - 230px)",
              }}
            >
              <PDFFile
                nome={nome}
                cpf={cpf}
                responsavel={usuario.nome}
                evolucao={conduta}
                crf={codigo_crf}
                crf_uf={codigo_crf_uf}
              />
            </PDFViewer>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCloseDialogPDF();
              }}
              color="secondary"
              variant="contained"
            >
              Fechar
            </Button>
            <Button
              onClick={() => {
                handleCloseDialogPDF();
                handleClickOpenDialogLacuna();
              }}
              color="secondary"
              variant="contained"
            >
              Assinar
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openDialogLacuna && (
        <Dialog
          open={openDialogLacuna}
          onClose={(_, reason) => {
            if (reason !== "backdropClick") {
              setOpenDialogLacuna(true);
            }
          }}
          maxWidth="lg"
          fullHeight
        >
          <DialogTitle>Lacuna - Assinatura Digital</DialogTitle>
          <DialogContent tyle={{ height: "900px", width: "900px" }}>
            <iframe
              src={
                "http://localhost:3003/pades-signature-restpki?code=" +
                codigoPDF +
                "&prontID=" +
                roomID +
                "&responsavel=" +
                usuario.cpf
              }
              style={{
                borderRadius: 5,
                border: "none",
                width: "calc(100vh - 300px)",
                height: "calc(100vh - 230px)",
              }}
            />
          </DialogContent>
          <Button
            onClick={() => {
              handleCloseDialogLacuna();
            }}
            color="secondary"
            variant="contained"
          >
            Fechar
          </Button>
        </Dialog>
      )}
      {mostrarForm && (
        <div>
          <p
            style={{
              textAlign: "center",
              verticalAlign: "center",
              justifyContent: "center",
              fontSize: "20px",
            }}
          >
            <b>CRONÔMETRO DA CONSULTA</b>
          </p>
          <div className="div2" style={{ fontSize: "30px" }}>
            <div>
              <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
            </div>
          </div>
          <div>
            <div className="item2">
              <button class="btCronometro" onClick={iniciarCronometro}>
                Iniciar
              </button>
              <button class="btCronometro" onClick={pausarCronometro}>
                Pausar
              </button>
              <button class="btCronometro" onClick={resetCronometro}>
                Resetar
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className="container"
        style={{
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateAreas: "'nav nav nav' 'main main main'",
        }}
      >
        {mostrarForm && (
          <nav className="navbar">
            <div className="nav_icon">
              <i className="fa fa-bars" aria-hidden="true"></i>
            </div>
            <div className="navbar__left">
              <a className="active_link" href="/">
                Painel
              </a>
            </div>
            <div className="navbar__right">
              {mostrarUsuario && (
                <label>
                  Olá <b>{usuario.nome}</b>!
                </label>
              )}
            </div>
          </nav>
        )}
        {mostrarForm && (
          <main style={{ overflow: "hidden" }}>
            <div className="main__container" style={{ height: "100%" }}>
              <div className="telaDividida" style={{ maxHeight: "100%" }}>
                {mostrarVideoAtendente() && (
                  <Container>
                   <div className="videoChamada">
                        {meetingUrl ? (
                          <iframe className="videoChamada"
                            src={meetingUrl.hostRoomUrl}
                            allow="camera; microphone; fullscreen; speaker; display-capture; compute-pressure"
                            title="Video Chamada Farmacêutico"
                          ></iframe>
                        ) : (
                          <p>Criando a reunião, por favor, aguarde...</p>
                        )}
                    </div>
                  </Container>
                )}
                <div
                  style={{
                    overflowY: "scroll",
                    width: "100%",
                  }}
                >
                  <div>
                    <div className="div_teste">
                      <div className="div2">
                        <div className="item">
                          <p>Nome: </p>
                          <input
                            value={nome}
                            onChange={(e) => {
                              setNome(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Origem: </p>
                          <select
                            value={origem}
                            onChange={(e) => {
                              setOrigem(e.target.value);
                            }}
                          >
                            <option>PROTRAC</option>
                            <option>Privado</option>
                            <option>SUS</option>
                            <option>Plano de Saúde</option>
                            <option>Eldorado</option>
                            <option>Demedia</option>
                            <option>HPS</option>
                            <option>Outro</option>
                            <option>Gravataí</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Data Nasc.: </p>
                          <input
                            min="1900-04-01"
                            max="2050-04-30"
                            type="date"
                            value={data_nasc}
                            onChange={(e) => {
                              setDataNasc(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>CPF: </p>
                          <InputMask
                            mask="999.999.999-99"
                            value={cpf}
                            onChange={(e) => {
                              setCpf(e.target.value);
                            }}
                          ></InputMask>
                        </div>
                        <div className="item">
                          <p>Sexo biológico: </p>
                          <select
                            value={sexoBiologico}
                            onChange={(e) => {
                              setSexoBiologico(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="M">Masculino</option>
                            <option value="F">Feminino</option>
                          </select>
                          <p>Identidade de gênero: </p>
                          <select
                            value={identidadeGenero}
                            onChange={(e) => {
                              setIdentidadeGenero(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="C">Cisgênero</option>
                            <option value="T">Transgênero</option>
                            <option value="N">Não-binário</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Altura (cm): </p>
                          <input
                            type="number"
                            value={altura}
                            onChange={(e) => {
                              setAltura(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Peso (kg): </p>
                          <input
                            type="number"
                            value={peso}
                            onChange={(e) => {
                              setPeso(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Estado Civil: </p>
                          <select
                            value={estado_civil}
                            onChange={(e) => {
                              setEstCivil(e.target.value);
                            }}
                          >
                            <option>Não Declarado</option>
                            <option>Solteiro</option>
                            <option>Casado</option>
                            <option>Viúvo</option>
                            <option>Divorciado</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Fone: </p>
                          <InputMask
                            mask="(99) 99999-9999"
                            value={fone}
                            onChange={(e) => {
                              setFone(e.target.value);
                              setShowWarning();
                            }}
                          ></InputMask>
                        </div>
                        <div className="item">
                          <p>CEP: </p>
                          <InputMask
                            mask="99999-999"
                            value={cep}
                            onChange={(e) => {
                              setCep(e.target.value);
                            }}
                          ></InputMask>
                        </div>
                        <div className="item">
                          <p>Nº Casa: </p>
                          <input
                            value={numeroCasa}
                            onChange={(e) => {
                              setNumeroCasa(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>UF: </p>
                          <input
                            value={uf}
                            onChange={(e) => {
                              setUf(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Cidade: </p>
                          <input
                            value={cidade}
                            onChange={(e) => {
                              setCidade(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Bairro: </p>
                          <input
                            value={bairro}
                            onChange={(e) => {
                              setBairro(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Logradouro: </p>
                          <input
                            value={rua}
                            onChange={(e) => {
                              setRua(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p> </p>
                          <button onClick={preencheCEP}>
                            Preencher endereço
                          </button>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Possui acompanhante: </p>
                          <select
                            value={possui_acompanhante}
                            onChange={(e) => {
                              setPossuiAcomp(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Nome do Acompanhante: </p>
                          <input
                            value={nome_acompanhante}
                            onChange={(e) => {
                              setAcompanhante(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Fone do Acompanhante: </p>
                          <InputMask
                            mask="(99) 99999-9999"
                            value={fone_acompanhante}
                            onChange={(e) => {
                              setFoneAcompanhante(e.target.value);
                            }}
                          ></InputMask>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Observações: </p>
                          <textarea
                            placeholder="Descrever sobre, por exemplo:  rotina de médicos/especialidades, diurese, evacuação, quantidade de ingesta de água, se dorme bem ou com auxílio de medicamentos..."
                            class="textoGrande"
                            value={obs}
                            onChange={(e) => {
                              setObs(e.target.value);
                              setShowWarning();
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Problemas de saúde</p>
                          <textarea
                            class="textoGrande"
                            value={problemas_saude}
                            onChange={(e) => {
                              setProblemasSaude(e.target.value);
                              setShowWarning();
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Evoluções:</p>
                          <textarea
                            class="textoGrande"
                            value={outras_obs}
                            onChange={(e) => {
                              setOutrasObs(e.target.value);
                              setShowWarning();
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>
                            Outras informações de suporte via WhatsApp Livre:
                          </p>
                          <textarea
                            class="textoGrande"
                            value={observacaoWhatsApp}
                            onChange={(e) => {
                              setObservacaoWhatsApp(e.target.value);
                              setShowWarning();
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Médico responsável: </p>
                          <input
                            value={medico_responsavel}
                            onChange={(e) => {
                              setMed(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>CRM: </p>
                          <input
                            value={crm_medico}
                            onChange={(e) => {
                              setCrm(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Fone do Médico: </p>
                          <InputMask
                            mask="(99) 99999-9999"
                            value={fone_medico}
                            onChange={(e) => {
                              setFoneMedico(e.target.value);
                            }}
                          ></InputMask>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p
                            style={{
                              textAlign: "center",
                              background: "green",
                              verticalAlign: "center",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Grau de dependência</p>
                          <select
                            value={grau_dependencia}
                            onChange={(e) => {
                              setGrau(e.target.value);
                            }}
                          >
                            <option>Não tem</option>
                            <option>Paciente cadeirante</option>
                            <option>Paciente acamado</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p
                            style={{
                              textAlign: "center",
                              background: "yellow",
                              verticalAlign: "center",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Esteve internado em Hospital?</p>
                          <select
                            value={internacao_hospitalar}
                            onChange={(e) => {
                              setInternacao(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="1">Sim</option>
                            <option value="2">Não</option>
                            <option value="3">Não se aplica</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Período</p>
                          <select
                            value={tempo_internacao}
                            onChange={(e) => {
                              setTempoInternacao(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="1">Nos últimos 30 dias</option>
                            <option value="2">Nos últimos 90 dias</option>
                            <option value="3">No último ano</option>
                            <option value="4">Não se aplica</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Esteve em serviço de emergência?</p>
                          <select
                            value={servico_emergencia}
                            onChange={(e) => {
                              setEmergencia(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="1">Sim</option>
                            <option value="2">Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Período</p>
                          <select
                            value={tempo_servico}
                            onChange={(e) => {
                              setTempoEmerg(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="1">Nos últimos 30 dias</option>
                            <option value="2">Nos últimos 90 dias</option>
                            <option value="3">No último ano</option>
                            <option value="4">Não se aplica</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p
                            style={{
                              textAlign: "center",
                              background: "black",
                              verticalAlign: "center",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Horário que acorda</p>
                          <input
                            type="time"
                            value={hora_acorda}
                            onChange={(e) => {
                              setHoraAcorda(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Horário que dorme</p>
                          <input
                            value={hora_dorme}
                            onChange={(e) => {
                              setHoraDorme(e.target.value);
                            }}
                            type="time"
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Café da manhã</p>
                          <input
                            value={cafe_manha}
                            onChange={(e) => {
                              setCafeManha(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Horário</p>
                          <input
                            value={hora_cafe}
                            onChange={(e) => {
                              setHoraCafe(e.target.value);
                            }}
                            type="time"
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Lanche</p>
                          <input
                            value={lanche_manha}
                            onChange={(e) => {
                              setLancheManha(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Horário</p>
                          <input
                            value={hora_lanche_manha}
                            onChange={(e) => {
                              setHoraLancheManha(e.target.value);
                            }}
                            type="time"
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Almoço</p>
                          <input
                            value={almoco}
                            onChange={(e) => {
                              setAlmoco(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Horário</p>
                          <input
                            value={hora_almoco}
                            onChange={(e) => {
                              setHoraAlmoco(e.target.value);
                            }}
                            type="time"
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Lanche</p>
                          <input
                            value={lanche_tarde}
                            onChange={(e) => {
                              setLancheTarde(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Horário</p>
                          <input
                            value={hora_lanche_tarde}
                            onChange={(e) => {
                              setHoraLancheTarde(e.target.value);
                            }}
                            type="time"
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Janta</p>
                          <input
                            value={janta}
                            onChange={(e) => {
                              setJanta(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Horário</p>
                          <input
                            value={hora_janta}
                            onChange={(e) => {
                              setHoraJanta(e.target.value);
                            }}
                            type="time"
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Atividade Física</p>
                          <select
                            value={ativ_fisica}
                            onChange={(e) => {
                              setAtivFisica(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Frequência</p>
                          <select
                            value={freq_ativ}
                            onChange={(e) => {
                              setFreqAtiv(e.target.value);
                            }}
                          >
                            <option>Não pratica</option>
                            <option>De 1 a 2 dias</option>
                            <option>De 3 a 6 dias</option>
                            <option>Todos os dias</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Qual atividade</p>
                          <input
                            value={qual_ativ}
                            onChange={(e) => {
                              setQualAtiv(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Lazer</p>
                          <input
                            value={lazer}
                            onChange={(e) => {
                              setLazer(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p
                            style={{
                              textAlign: "center",
                              background: "red",
                              verticalAlign: "center",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Exames</p>
                          <textarea
                            class="textoGrande"
                            value={exames}
                            onChange={(e) => {
                              setExames(e.target.value);
                              setShowWarning();
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Queixas</p>
                          <textarea
                            class="textoGrande"
                            value={queixas}
                            onChange={(e) => {
                              setQueixas(e.target.value);
                              setShowWarning();
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Alergias</p>
                          <textarea
                            class="textoGrande"
                            value={alergias}
                            onChange={(e) => {
                              setAlergias(e.target.value);
                              setShowWarning();
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Vacinas</p>
                          <div className="vacinas">
                            <label className="vacinas">
                              Caxumba
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaCaxumba}
                                onChange={(e) => {
                                  setVacinaCaxumba(!vacinaCaxumba);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              Coqueluche
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaCoqueluche}
                                onChange={(e) => {
                                  setVacinaCoqueluche(!vacinaCoqueluche);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              Covid-19
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaCovid}
                                onChange={(e) => {
                                  setVacinaCovid(!vacinaCovid);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              Difteria
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaDifteria}
                                onChange={(e) => {
                                  setVacinaDifteria(!vacinaDifteria);
                                }}
                              />{" "}
                            </label>

                            <label className="vacinas">
                              Febre amarela
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaFebreAmarela}
                                onChange={(e) => {
                                  setVacinaFebreAmarela(!vacinaFebreAmarela);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              Herpes Zóster
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaHerpes}
                                onChange={(e) => {
                                  setVacinaHerpes(!vacinaHerpes);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              Hepatite B
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaHepatiteB}
                                onChange={(e) => {
                                  setVacinaHepatiteB(!vacinaHepatiteB);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              HPV
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaHPV}
                                onChange={(e) => {
                                  setVacinaHPV(!vacinaHPV);
                                }}
                              />{" "}
                            </label>

                            <label className="vacinas">
                              Influenza (gripe)
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaInfluenza}
                                onChange={(e) => {
                                  setVacinaInfluenza(!vacinaInfluenza);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              Pneumococica
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaPneumococica}
                                onChange={(e) => {
                                  setVacinaPneumococica(!vacinaPneumococica);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              Rubéola
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaRubeola}
                                onChange={(e) => {
                                  setVacinaRubeola(!vacinaRubeola);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              Sarampo
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaSarampo}
                                onChange={(e) => {
                                  setVacinaSarampo(!vacinaSarampo);
                                }}
                              />{" "}
                            </label>
                            <label className="vacinas">
                              Tétano
                              <input
                                className="radio"
                                type="radio"
                                checked={vacinaTetano}
                                onChange={(e) => {
                                  setVacinaTetano(!vacinaTetano);
                                }}
                              />{" "}
                            </label>
                          </div>

                          <div className="item">
                            <button onClick={limpaVacinas}>Limpar</button>
                          </div>
                        </div>
                      </div>
                      <div className="div2">
                        <div
                          className="item"
                          style={{ overflowY: "auto", maxHeight: "400px" }}
                        >
                          <table
                            className="content-table"
                            style={{ margin: "0px" }}
                          >
                            <thead>
                              <tr>
                                <th>Data</th>
                                <th>Ação</th>
                                <th>Medicamento</th>
                                <th>Dose</th>
                                <th>Posologia</th>
                                <th>Antes/Depois da Refeição</th>
                                <th>Tempo de Uso</th>
                                <th>Mesmo Lab.?</th>
                                <th>Mesmo horário?</th>
                                <th>Motivo Uso</th>
                                <th>Quem indicou?</th>
                                <th>Necessidade</th>
                                <th>Efetividade</th>
                                <th>Segurança</th>
                                <th>Data da 1ª Dose</th>
                                <th>Observação</th>
                                <th>Adesão - 48 Horas</th>
                              </tr>
                            </thead>
                            <tbody align="justify">
                              {listaMedicamentos.map((item) => (
                                <tr>
                                  <th>{formatDate(item.data)}</th>
                                  <th
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      flexDirection: "column",
                                      cursor: "pointer",
                                      alignContent: "middle",
                                    }}
                                  >
                                    <a
                                      id={item.id}
                                      onClick={(e) =>
                                        consultaMedicamento(e.target.id)
                                      }
                                    >
                                      Editar
                                    </a>
                                    <a
                                      id={item.id}
                                      onClick={(e) => excluirMed(e.target.id)}
                                    >
                                      Excluir
                                    </a>
                                  </th>
                                  <th>{item.medicamento}</th>
                                  <th>{item.dose}</th>
                                  <th>{item.posologia}</th>
                                  <th>{item.antes_depois_refeicao}</th>
                                  <th>{item.tempo_uso}</th>
                                  <th>{item.mesmo_lab}</th>
                                  <th>{item.mesmo_horario}</th>
                                  <th>{item.motivo_uso}</th>
                                  <th>{item.quem_indicou}</th>
                                  <th>{item.necessario}</th>
                                  <th>{item.efetividade}</th>
                                  <th>{item.seguranca}</th>
                                  <th>{item.data_primeira_dose ? formatDate(item.data_primeira_dose)+" "+(item.data_primeira_dose).substring(11) : ''}</th>
                                  <th>{item.observacao}</th>
                                  <th className={Math.round(item.adesao) < 80 ? 'red-percentual': 'green-percentual'}>{Math.round(item.adesao)} %</th>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Medicamento</p>
                          <input
                            value={medicamento}
                            onChange={(e) => {
                              setMedicamento(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Dose</p>
                          <input
                            value={dose}
                            onChange={(e) => {
                              setDose(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Data da 1ª Dose</p>
                          <input
                            min="1900-04-01T00:00"
                            max="2050-04-30T23:49"
                            type="datetime-local"
                            value={dataPrimeiraDose}
                            onChange={(e) => {
                              setDataPrimeiraDose(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Posologia</p>
                          <select
                            value={posologia}
                            onChange={(e) => {
                              setPosologia(e.target.value);
                            }}
                          >
                            <option>4/4h</option>
                            <option>6/6h</option>
                            <option>8/8h</option>
                            <option>12/12h</option>
                            <option>A cada 24h</option>
                            <option>Se necessário</option>
                            <option>Outro</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Tempo de Uso</p>
                          <select
                            value={tempoUso}
                            onChange={(e) => {
                              setTempoUso(e.target.value);
                            }}
                          >
                            <option>7 dias</option>
                            <option>10 dias</option>
                            <option>15 dias</option>
                            <option>21 dias</option>
                            <option>30 dias</option>
                            <option>Contínuo</option>
                            <option>Em crise</option>
                            <option>Outro</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                      <div className="item">
                          <p>Antes/Depois Refeição</p>
                          <select
                            value={momentoMedicamento}
                            onChange={(e) => {
                              setMomentoMedicamento(e.target.value);
                            }}
                          >
                            <option>Antes</option>
                            <option>Depois</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Mesmo Lab.?</p>
                          <select
                            value={mesmoLab}
                            onChange={(e) => {
                              setMesmoLaboratorio(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Mesmo Horário?</p>
                          <select
                            value={mesmoHorario}
                            onChange={(e) => {
                              setMesmoHorario(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Motivo do uso?</p>
                          <input
                            value={motivoUso}
                            onChange={(e) => {
                              setMotivoUso(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Quem indicou?</p>
                          <input
                            value={quemIndicou}
                            onChange={(e) => {
                              setQuemIndicou(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Necessidade</p>
                          <input
                            value={necessidade}
                            onChange={(e) => {
                              setNecessidade(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Efetividade</p>
                          <input
                            value={efetividade}
                            onChange={(e) => {
                              setEfetividade(e.target.value);
                            }}
                          ></input>
                        </div>
                        
                        <div className="item">
                          <p>Segurança</p>
                          <input
                            value={seguro}
                            onChange={(e) => {
                              setSeguro(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                      <div className="item">
                          <p>Observação</p>
                          <textarea
                            placeholder="Observações sobre o uso do medicamento"
                            class="textoGrande"
                            value={observacaoMedicamento}
                            onChange={(e) => {
                              setObservacaoMedicamento(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p style={{ color: "red" }}>
                            Vamos combinar uma coisa? Caso algum desses
                            medicamentos você nunca tenha tomado, nos informe se
                            sentir algum efeito indesejado! Ah, não esqueça,
                            antes de tomar qualquer medicamento, entre em
                            contato com o seu farmacêutico!!! Estamos aqui para
                            ajudar!!
                          </p>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          {isAddMedicamento && (
                            <button onClick={salvaMedicamento}>
                              <i className="fa fa-plus"></i> Adicionar
                            </button>
                          )}
                          {isEditMedicamento && (
                            <button onClick={alteraMedicamento}>
                              <i className="fa fa-plus"></i> Atualizar
                            </button>
                          )}
                        </div>
                        <div className="item">
                          <button onClick={limpaCamposMedicamentos}>
                            <i className="fa fa-plus"></i> Limpar
                          </button>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>
                            Medicamento(s)que utilizou e causou efeitos
                            adversos( não tolerou)
                          </p>
                          <input
                            value={medicamentosEfeitosAdversos}
                            onChange={(e) => {
                              setMedicamentosEfeitosAdversos(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Medicamento(s) que tomou e não foram efetivos</p>
                          <input
                            value={medicamentosSemEfetividade}
                            onChange={(e) => {
                              setMedicamentosSemEfetividade(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <h3>Efeitos adversos</h3>
                        </div>
                      </div>
                      <div className="div2">
                        <div
                          className="item"
                          style={{ overflowY: "auto", maxHeight: "400px" }}
                        >
                          <table
                            className="content-table"
                            style={{ margin: "0px" }}
                          >
                            <thead>
                              <tr>
                                <th>Data</th>
                                <th>Medicamento</th>
                                <th>Efeito adverso</th>
                                <th>Ação</th>
                              </tr>
                            </thead>
                            <tbody align="justify">
                              {listaEfeitosAdversos.map((item) => (
                                <tr>
                                  <th>{formatDate(item.data)}</th>
                                  <th>{item.medicamento}</th>
                                  <th>{item.efeito}</th>
                                  <th
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      flexDirection: "column",
                                      alignContent: "middle",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <a
                                      id={item.id}
                                      onClick={(e) =>
                                        consultaEfeitoAdverso(e.target.id)
                                      }
                                    >
                                      Editar
                                    </a>
                                    <a
                                      id={item.id}
                                      onClick={(e) =>
                                        excluirEfeitoAdverso(e.target.id)
                                      }
                                    >
                                      Excluir
                                    </a>
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Data</p>
                          <input
                            min="1900-04-01"
                            max="2050-04-30"
                            type="date"
                            value={dataEfeitoAdverso}
                            onChange={(e) => {
                              setDataEfeitoAdverso(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Medicamento</p>
                          <input
                            value={efeitoAdversoMedicamento}
                            onChange={(e) => {
                              setEfeitoAdversoMedicamento(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Observação</p>
                          <textarea
                            class="textoGrande"
                            value={efeitoAdverso}
                            onChange={(e) => {
                              setEfeitoAdverso(e.target.value);
                              setShowWarning();
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          {isAddEfeitoAdverso && (
                            <button onClick={salvaEfeitoAdverso}>
                              <i className="fa fa-plus"></i> Adicionar
                            </button>
                          )}
                          {isEditEfeitoAdverso && (
                            <button onClick={alteraEfeitoAdverso}>
                              <i className="fa fa-plus"></i> Atualizar
                            </button>
                          )}
                        </div>
                        <div className="item">
                          <button onClick={limpaCampoEfeitoAdverso}>
                            <i className="fa fa-plus"></i> Limpar
                          </button>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p
                            style={{
                              textAlign: "center",
                              background: "blue",
                              verticalAlign: "center",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Responsável pela adm. da medicação</p>
                          <select
                            value={resp_medicacao}
                            onChange={(e) => {
                              setRespMedicacao(e.target.value);
                            }}
                          >
                            <option>Paciente</option>
                            <option>Outro</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Onde armazena a medicação</p>
                          <input
                            value={armazena_medicacao}
                            onChange={(e) => {
                              setArmazenaMedicacao(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Controla o prazo de validade</p>
                          <select
                            value={controla_validade}
                            onChange={(e) => {
                              setControlaValidade(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Descarte de medicamentos</p>
                          <select
                            value={controla_validade}
                            onChange={(e) => {
                              setControlaValidade(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="1">Lixo comum</option>
                            <option value="2">Vaso sanitário</option>
                            <option value="3">Queima em casa </option>
                            <option value="4">
                              Entrega para farmácia/unidade de saúde/hospital
                            </option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Outra opção de descarte</p>
                          <input
                            value={descarteMedicamentoOutro}
                            onChange={(e) => {
                              setDescarteMedicamentoOutro(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <h2>Adesão</h2>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Escala Morisky</p>
                          <textarea
                            class="textoGrande"
                            value={adesaoMorisky}
                            onChange={(e) => {
                              setAdesaoMorisky(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Identifique o motivo:</p>
                          <select
                            value={razaoAdesaoMorisky}
                            onChange={(e) => {
                              setRazaoAdesaoMorisky(e.target.value);
                            }}
                          >
                            <option value=""></option>
                            <option value="1">
                              Não tem como adquirir o medicamento (problema
                              financeiro)
                            </option>
                            <option value="2">Não entende a doença</option>
                            <option value="3">Esquece de tomar</option>
                            <option value="4">Devido efeitos adversos</option>
                            <option value="5">Não tem receita</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Outro motivo:</p>
                          <input
                            value={razaoAdesaoMoriskyOutro}
                            onChange={(e) => {
                              setRazaoAdesaoMoriskyOutro(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p
                            style={{
                              textAlign: "center",
                              background: "yellow",
                              verticalAlign: "center",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Fuma</p>
                          <select
                            value={fuma}
                            onChange={(e) => {
                              setFuma(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Quantidade ao dia</p>
                          <input
                            value={qtd_fuma}
                            onChange={(e) => {
                              setQtdFuma(e.target.value);
                            }}
                          ></input>
                        </div>

                        <div className="item">
                          <p>Ex-fumante?</p>
                          <select
                            value={exFumante}
                            onChange={(e) => {
                              setExFumante(e.target.value);
                            }}
                          >
                            <option value="0">Não</option>
                            <option value="1">Sim</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Quantidade ao dia</p>
                          <input
                            value={exFumante_qtd}
                            onChange={(e) => {
                              setExFumante_qtd(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Período</p>
                          <input
                            value={exFumante_periodo}
                            onChange={(e) => {
                              setExFumante_periodo(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Álcool</p>
                          <select
                            value={usa_alcool}
                            onChange={(e) => {
                              setUsaAlcool(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Ex-etilista</p>
                          <select
                            value={exEtilista}
                            onChange={(e) => {
                              setExEtilista(e.target.value);
                            }}
                          >
                            <option value="1">Sim</option>
                            <option value="0">Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Tipo bebida</p>
                          <input
                            value={tipo_alcool}
                            onChange={(e) => {
                              setTipoAlcool(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Quantidade</p>
                          <input
                            value={qtd_alcool}
                            onChange={(e) => {
                              setQtdAlcool(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Frequência</p>
                          <select
                            value={freq_alcool}
                            onChange={(e) => {
                              setFreqAlcool(e.target.value);
                            }}
                          >
                            <option>Não utiliza</option>
                            <option>De 1 a 2 dias</option>
                            <option>De 3 a 6 dias</option>
                            <option>Todos os dias</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Café</p>
                          <select
                            value={cafe}
                            onChange={(e) => {
                              setTomaCafe(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Quantidade/Dia/Xícara</p>
                          <input
                            value={qtd_cafe}
                            onChange={(e) => {
                              setQtdCafe(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Chá</p>
                          <select
                            value={cha}
                            onChange={(e) => {
                              setTomaCha(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Qual</p>
                          <input
                            value={qual_cha}
                            onChange={(e) => {
                              setQualCha(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Quantidade</p>
                          <input
                            value={qtd_cha}
                            onChange={(e) => {
                              setQtdCha(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Frequência</p>
                          <select
                            value={freq_cha}
                            onChange={(e) => {
                              setFreqCha(e.target.value);
                            }}
                          >
                            <option>Não utiliza</option>
                            <option>De 1 a 2 dias</option>
                            <option>De 3 a 6 dias</option>
                            <option>Todos os dias</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Possui alguma dependência química?</p>
                          <select
                            value={dependencia_quimica}
                            onChange={(e) => {
                              setDependQuimica(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Se sim, qual?</p>
                          <input
                            value={qual_dependencia}
                            onChange={(e) => {
                              setQualDep(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Chimarrão</p>
                          <select
                            value={chimarrao}
                            onChange={(e) => {
                              setChimarrao(e.target.value);
                            }}
                          >
                            <option>Sim</option>
                            <option>Não</option>
                          </select>
                        </div>
                        <div className="item">
                          <p>Quantidade</p>
                          <input
                            value={qtd_chimarrao}
                            onChange={(e) => {
                              setQtdChimarrao(e.target.value);
                            }}
                          ></input>
                        </div>
                        <div className="item">
                          <p>Frequência</p>
                          <select
                            value={freq_chimarrao}
                            onChange={(e) => {
                              setFreqChimarrao(e.target.value);
                            }}
                          >
                            <option>Eventualmente</option>
                            <option>De 1 a 2 dias</option>
                            <option>De 3 a 6 dias</option>
                            <option>Todos os dias</option>
                          </select>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Como está o seu tratamento?</p>
                          <input
                            value={situacao_tratamento}
                            onChange={(e) => {
                              setSituacaoTratamento(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Por quê você acha que tem a doença?</p>
                          <input
                            value={motivo_doenca}
                            onChange={(e) => {
                              setMotivoDoenca(e.target.value);
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="div2">
                        <div
                          className="item"
                          style={{ overflowY: "auto", maxHeight: "400px" }}
                        >
                          <table
                            className="content-table"
                            style={{ margin: "0px" }}
                          >
                            <thead>
                              <tr>
                                <th>Data</th>
                                <th>Conduta</th>
                                <th>Observações</th>
                                <th>Farm. Responsável</th>
                                <th>Ação</th>
                              </tr>
                            </thead>
                            <tbody align="justify">
                              {listConduta.map((item) => (
                                <tr>
                                  <th>{formatDate(item.data)}</th>
                                  <th>{item.conduta}</th>
                                  <th>{item.observacao}</th>
                                  <th>{item.responsavel}</th>
                                  <th
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      flexDirection: "column",
                                      alignContent: "middle",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <a
                                      id={item.id}
                                      onClick={(e) =>
                                        consultaConduta(e.target.id)
                                      }
                                    >
                                      Editar
                                    </a>
                                    <a
                                      id={item.id}
                                      onClick={(e) =>
                                        excluirConduta(e.target.id)
                                      }
                                    >
                                      Excluir
                                    </a>
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Conduta</p>
                          <textarea
                            class="textoGrande"
                            value={conduta}
                            onChange={(e) => {
                              setConduta(e.target.value);
                              setShowWarning();
                            }}
                          ></textarea>
                        </div>
                        <div className="item">
                          <p>Encerramento</p>
                          <label>
                            <input
                              type="checkbox"
                              checked={encerrramento}
                              onChange={() => {
                                setEncerrramento(!encerrramento);
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          {isAddConduta && (
                            <button onClick={salvaConduta}>
                              <i className="fa fa-plus"></i> Adicionar
                            </button>
                          )}
                          {isEditConduta && (
                            <button onClick={alteraConduta}>
                              <i className="fa fa-plus"></i> Atualizar
                            </button>
                          )}
                        </div>
                        <div className="item">
                          <button onClick={limpaCampoCondutas}>
                            <i className="fa fa-plus"></i> Limpar
                          </button>
                        </div>
                      </div>

                      <div className="div2">
                        <div className="item">
                          <p
                            style={{
                              textAlign: "center",
                              background: "green",
                              verticalAlign: "center",
                            }}
                          ></p>
                        </div>
                      </div>

                      <div className="div2">
                        <div
                          className="item"
                          style={{ overflowY: "auto", maxHeight: "400px" }}
                        >
                          <table
                            className="content-table"
                            style={{ margin: "0px" }}
                          >
                            <thead>
                              <tr>
                                <th>Data</th>
                                <th>Informação</th>
                                <th>Farm. Responsável</th>
                                <th>Ação</th>
                              </tr>
                            </thead>
                            <tbody align="justify">
                              {listaOutrasInformacoes.map((item) => (
                                <tr>
                                  <th>{formatDate(item.data)}</th>
                                  <th>{item.informacao}</th>
                                  <th>{item.responsavel}</th>
                                  <th
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      flexDirection: "column",
                                      alignContent: "middle",
                                    }}
                                  >
                                    <a
                                      style={{ cursor: "pointer" }}
                                      id={item.id}
                                      onClick={(e) =>
                                        excluirOutrasInformacoes(e.target.id)
                                      }
                                    >
                                      Excluir
                                    </a>
                                  </th>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <p>Informação</p>
                          <input
                            value={outrasInformacoes}
                            type="textarea"
                            placeholder="Insira aqui outras informações importantes para o atendimento"
                            onChange={(e) => {
                              setOutrasInformacoes(e.target.value);
                              setShowWarning();
                            }}
                          ></input>
                        </div>
                      </div>

                      <div className="div2">
                        <div className="item">
                          <button onClick={salvaOutrasInformacoes}>
                            <i className="fa fa-plus"></i> Adicionar
                          </button>
                        </div>
                      </div>

                      <div className="div2">
                        <div className="item">
                          <p
                            style={{
                              textAlign: "center",
                              background: "black",
                              verticalAlign: "center",
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <button
                            className="btn-pdfgen"
                            onClick={handleClickOpenDialogPDF}
                            style={{ backgroundColor: "orange" }}
                          >
                            Gerar PDF
                          </button>
                        </div>
                      </div>
                      <div className="div2">
                        <div className="item">
                          <button onClick={salvaForm}>Finalizar</button>
                        </div>
                      </div>

                      
                    </div>
                    {Prompt}
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default Room;