import React, { useEffect, useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { formatDate } from "../../utils/date";

const TelecAberta = (props) => {
  Axios.defaults.withCredentials = true;

  const [lista, setLista] = useState([]);
  let history = useHistory();

  useEffect(() => {
    Axios.defaults.withCredentials = false;

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/telecAbertas").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      //console.log(response);
      setLista(response.data);
    });
  }, []);

  const abrirTeleconsulta = (id) => {
    history.push({
      pathname: "/adm/detalhes",
      state: { detail: id },
    });
  };

  return (
    <>
      <SidebarAdministrativo>
        <div class="main__container">
          <table class="content-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>SOLICITANTE</th>
                <th>DATA</th>
                <th>ÁREA INTERESSE</th>
                <th>STATUS</th>
                <th>AÇÃO</th>
              </tr>
            </thead>
            <tbody align="justify">
              {lista.map((item) => (
                <tr>
                  <th>{item.id}</th>
                  <th>{item.nome}</th>
                  <th>{formatDate(item.data_hora)}</th>
                  <th>{item.area_interesse}</th>
                  <th>{item.status}</th>
                  <th>
                    <th>
                      <a
                        id={item.id}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => abrirTeleconsulta(e.target.id)}
                      >
                        Abrir
                      </a>
                    </th>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </SidebarAdministrativo>
    </>
  );
};

export default TelecAberta;
