import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../App.css";
import { useHistory } from "react-router";

export default function RegistrationPaciente() {
  let history = useHistory();
  const [cpfReg, setCpfReg] = useState("");
  const [usernameReg, setUsernameReg] = useState("");
  const [passwordReg, setPasswordReg] = useState("");
  const [nomeReg, setNomeReg] = useState("");
  const [cepReg, setCepReg] = useState("");
  const [ruaReg, setRuaReg] = useState("");
  const [cidadeReg, setCidadeReg] = useState("");
  const [bairroReg, setBairroReg] = useState("");
  const [complementoReg, setComplementoReg] = useState("");
  const [estadoReg, setEstadoReg] = useState("");
  const [crxReg, setCrxReg] = useState("");
  const [crxUfReg, setCrxUfReg] = useState("");
  const [tipoUsuarioReg, setTipoUsuarioReg] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loginStatus, setLoginStatus] = useState("");
  const [especialidadeReg, setEspecialidadeReg] = useState("");

  Axios.defaults.withCredentials = true;

  const register = () => {
    Axios.post("http://localhost:3000:3001/register", {
      username: usernameReg,
      password: passwordReg,
      nome: nomeReg,
      cpf: cpfReg,
      cep: cepReg,
      rua: ruaReg,
      cidade: cidadeReg,
      bairro: bairroReg,
      complemento: complementoReg,
      estado: estadoReg,
      crx: crxReg,
      crxuf: crxUfReg,
      tipoUsuario: tipoUsuarioReg,
      especialidade: especialidadeReg,
    }).then((response) => {});
  };

  const login = () => {
    Axios.post("http://localhost:3000:3001/login", {
      username: username,
      password: password,
    }).then((response) => {
      if (response.data.message) {
        setLoginStatus(response.data.message);
      }
      if (!response.data.message) {
        alert(response.data[0].username);
      }
    });
  };

  useEffect(() => {
    Axios.defaults.withCredentials = true;
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/login", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
        setLoginStatus(response.data.user[0].username);
      } else {
        history.push("/loginpaciente");
      }
    });

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/logout", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
        history.push("/loginpaciente");
      } else {
        history.push("/loginpaciente");
      }
    });
  });

  return <div></div>;
}
