import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { getCurrentDate } from "../../utils/date";

const AlterarVinculoProntuario = (props) => {
  /*
    Nesta tela é realizado a alteração do vínculo do prontuário.
    Foi definidio o valor "" para os options de cada elemento, permitindo que seja alterado sem maiores problemas.
    Sendo assim, é alterado todos os campos do vinculo, mesmo que não esteja marcado.
    */
  const [idTeleA, setIdTeleA] = useState();
  const [teleaID, setTeleaID] = useState("");
  const [motivo, setMotivo] = useState();
  const [listaTeleAss, setListaTeleAss] = useState([]);
  const [listaFarmaceutico, setListaFarmaceutico] = useState([]);
  const [listaNutricao, setListaNutricao] = useState([]);
  const [listaPsicologia, setListaPsicologia] = useState([]);
  const [listaEducadorFisico, setListaEducadorFisico] = useState([]);
  const [educadorFisico, setEducadorFisico] = useState("");
  const [farmaceutico, setFarmaceutico] = useState("");
  const [nutricao, setNutricao] = useState("");
  const [psicologia, setPsicologia] = useState("");
  Axios.defaults.withCredentials = true;
  let history = useHistory();

  const atualizar = () => {
    if (
      nutricao === "" &&
      psicologia === "" &&
      farmaceutico === "" &&
      educadorFisico === ""
    ) {
      alert("Selecione um vínculo para o prontuário");
    } else {
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/alterarVinculoProntuario", {
        teleaID: teleaID,
        id_farmaceutico: farmaceutico,
        id_nutricao: nutricao,
        id_psicologo: psicologia,
        id_educador_fisico: educadorFisico,
      }).then((response) => {
        if (response.data.message == "1") {
          alert("Teleconsulta atualizada com sucesso!");
          history.push("/adm");
        } else {
          alert("Erro ao atualizar Teleconsulta!");
        }
      });
    }
  };

  const pesquisaDados = () => {
    setFarmaceutico("");
    setNutricao("");
    setPsicologia("");
    setEducadorFisico("");
    console.log(idTeleA);
    if (teleaID != "") {
      Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaTeleAssistenciaByID", {
        id: teleaID,
      }).then((response) => {
        setFarmaceutico(response.data[0].id_farmaceutico);
        setNutricao(response.data[0].id_nutricao);
        setPsicologia(response.data[0].id_psicologo);
        setEducadorFisico(response.data[0].id_educador_fisico);
      });
    }
  };

  useEffect(() => {
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/lista_farmaceuticos").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaFarmaceutico(response.data);
    });

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/lista_educador_fisico").then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
        }
        console.log(response.data[0]);
        setListaEducadorFisico(response.data);
      }
    );

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/lista_nutricao").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaNutricao(response.data);
    });

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/lista_psicologia").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      console.log(response.data[0]);
      setListaPsicologia(response.data);
    });

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaIDNomeTeleAssistencia").then(
      (response) => {
        if (response.data.message) {
          alert(response.data.message);
        }
        console.log(response.data[0]);
        setListaTeleAss(response.data);
      }
    );
  }, []);

  return (
    <>
      <SidebarAdministrativo>
        <div class="main__container">
          <div>
            <div class="titulo">
              <h3>Alterar Vínculo - Teleconsulta</h3>
            </div>
            <div class="div_teste">
              <div className="div2">
                <div className="item">
                  <p>ID da Assistência: </p>
                  <input
                    value={teleaID}
                    type="text"
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="item">
                  <p>Selecionar Teleconsulta: </p>
                  <select
                    value={teleaID}
                    onChange={(e) => {
                      setTeleaID(e.target.value);
                    }}
                  >
                    <option value="">-</option>
                    {listaTeleAss.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="div2">
                <div class="item">
                  <button onClick={pesquisaDados}>PESQUISAR</button>
                </div>
              </div>
              <div className="div2">
                <div className="item">
                  <p>Farmacêutico: </p>
                  <select
                    value={farmaceutico}
                    onChange={(e) => {
                      setFarmaceutico(e.target.value);
                    }}
                  >
                    <option value="0">-</option>
                    {listaFarmaceutico.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="div2">
                <div className="item">
                  <p>Psicologo: </p>
                  <select
                    value={psicologia}
                    onChange={(e) => {
                      setPsicologia(e.target.value);
                    }}
                  >
                    <option value="">-</option>
                    {listaPsicologia.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="div2">
                <div className="item">
                  <p>Nutrição: </p>
                  <select
                    value={nutricao}
                    onChange={(e) => {
                      setNutricao(e.target.value);
                    }}
                  >
                    <option value="">-</option>
                    {listaNutricao.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="div2">
                <div className="item">
                  <p>Educador Físico: </p>
                  <select
                    value={educadorFisico}
                    onChange={(e) => {
                      setEducadorFisico(e.target.value);
                    }}
                  >
                    <option value="">-</option>
                    {listaEducadorFisico.map((option) => (
                      <option value={option.id}>{option.nome}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div class="div2">
                <div class="item">
                  <p></p>
                  <button onClick={atualizar}>ATUALIZAR</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarAdministrativo>
    </>
  );
};

export default AlterarVinculoProntuario;
