import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import InputMask from "react-input-mask";


Axios.defaults.withCredentials = true;

const GerenciarFarmaceuticos = (props) => {

    const [lista, setLista] = useState([]);
    const [listaFarmaceutico, setListaFarmaceutico] = useState([]);
    const [estado, setEstado] = useState("");
    const [especialidade, setEspecialidade] = useState("");
    const [itemDigitado, setItemDigitado] = useState("");
    const [crxUf, setCrxUF] = useState("");
    const resultadoDaBusca = lista.filter(listVal => listVal.nome != null && (listVal.nome.toUpperCase().includes(itemDigitado.toUpperCase())
        || listVal.email.toString().includes(itemDigitado)));
    let history = useHistory();

    useEffect(() => {

        Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/lista_farmaceuticos").then((response) => {
            if (response.data.message) {
                alert(response.data.message);
            }
            console.log(response);
            setLista(response.data);
            setListaFarmaceutico(response.data);
        });
    }, []);

    const filtros = () => {
        let temp = [...listaFarmaceutico];
        if (estado != "") {
            const results = temp.filter((item) => {
                return item.estado === estado;
            });
            temp = [...results];
        }
        if (crxUf != "") {
            const results = temp.filter((item) => {
                return item.uf === crxUf;
            });
            temp = [...results];
        }

        if (especialidade != "") {
            const results = temp.filter((item) => {
                return item.especialidade === especialidade;
            });
            temp = [...results];
        }

        setLista(temp);
    };


    const handleHistoricoConsulta = (id) => {
        history.push({
            pathname: "/adm/historicoConsulta",
            state: { detail: id },
        });
    };
    const handleHbloqueio = (cpf) => {
        history.push({
            pathname: "/adm/bloqueioFarmaceutico",
            state: { detail: cpf },
        });
    };

    const handleHistoricoPagamento = (id) => {
        history.push({
            pathname: "/adm/historicoPagamento",
            state: { detail: id },
        });
    };


    return (
        <>
            <SidebarAdministrativo>
                <div className="titulo">
                    <h3>GERENCIAR FARMACÊUTICOS</h3>
                </div>

                <div class="div2">
                    <div class="item">
                        <p>Farmacêutico: </p>
                        <InputMask
                            class="nome"
                            type="text"
                            value={itemDigitado}
                            onChange={(e) => setItemDigitado(e.target.value)}
                            onKeyUp={(e) => setItemDigitado(e.target.value)}
                            placeholder="Digite o NOME do FARMACÊUTICO ou o EMAIL do FARMACÊUTICO"
                        ></InputMask>
                    </div>
                </div>
                <div class="subtitulo2">
                    <h3>Filtros </h3>
                </div>
                <div class="div2">
                    <div class="item">
                        <p>Estado: </p>
                        <select
                            value={estado}
                            id="estado"
                            name="estado"
                            onChange={(e) => {
                                setEstado(e.target.value);
                            }}
                        >
                            <option></option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>

                        </select>
                    </div>
                    <div class="item">
                        <p>CRF-UF: </p>
                        <select
                            value={crxUf}
                            id="crf"
                            name="crf"
                            onChange={(e) => {
                                setCrxUF(e.target.value);
                            }}
                        >
                            <option></option>
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MT">MT</option>
                            <option value="MS">MS</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PR">PR</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                        </select>
                    </div>
                    <div class="item">
                        <p>Especialidade: </p>
                        <select
                            value={especialidade}
                            id="especialidade"
                            name="especialidade"
                            onChange={(e) => {
                                setEspecialidade(e.target.value);
                            }}
                        >
                            <option></option>
                            <option>Psiquiatria</option>
                            <option>Neurologia</option>
                            <option>Oncologia</option>
                            <option>Cardiologia</option>
                            <option>Respiratórias</option>
                            <option>Emergência</option>
                            <option>Doenças metabólicas</option>
                            <option>Cirurgia</option>
                            <option>Odontologia</option>
                            <option>Microbiologia</option>
                            <option>Sistema Digestório</option>
                            <option>Doenças autoimunes</option>
                            <option>Geral</option>
                        </select>
                    </div>
                    <div class="item">
                        <p></p>
                        <button onClick={filtros}>SELECIONAR</button>
                    </div>
                </div>
                <table class="content-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>NOME</th>
                            <th>EMAIL</th>
                            <th>ESTADO</th>
                            <th>CIDADE</th>
                            <th>TELEFONE</th>
                            <th>ESPECIALIDADE</th>
                            <th>CRF</th>
                            <th>CRF-UF</th>
                            <th>AÇÕES</th>
                        </tr>
                    </thead>
                    <tbody align="justify">
                        {resultadoDaBusca.map((item) => (
                            <tr key={item.id}>
                                <th>{item.id}</th>
                                <th>{item.nome}</th>
                                <th>{item.email}</th>
                                <th>{item.estado}</th>
                                <th>{item.cidade}</th>
                                <th>{item.telefone}</th>
                                <th>{item.especialidade}</th>
                                <th>{item.registro}</th>
                                <th>{item.uf}</th>
                                <th>
                                    <div
                                        class="fa fa-history"
                                        title="Histórico de Consultas"
                                        style={{ cursor: "pointer", marginRight: "15px",fontSize: "30px" }}
                                        id={item.id}
                                        onClick={(e) => handleHistoricoConsulta(e.target.id)}
                                    >
                                    </div>
                                    <div
                                        class="fa fa-ban"
                                        title="Bloquear / Desbloquear"
                                        style={{ cursor: "pointer", marginRight: "15px",fontSize: "30px"  }}
                                        cpf={item.cpf}
                                        onClick={() => handleHbloqueio(item.cpf)}
                                    >
                                    </div>
                                    <div
                                        class="fa fa-credit-card"
                                        title="Histórico de Pagamentos"
                                        style={{ cursor: "pointer", marginRight: "15px",fontSize: "30px"  }}
                                        id={item.id}
                                        onClick={(e) => handleHistoricoPagamento(e.target.id)}
                                    >
                                    </div>
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </SidebarAdministrativo>
        </>
    );
};


export default GerenciarFarmaceuticos;
