import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router";
import Loading from "../pages/Loading";
import Axios from "axios";

const SolicitanteRoute = (props) => {
  const [tipoUser, setTipoUser] = useState("8");
  const [LoadingAdm, setLoadingAdm] = useState(true);

  useEffect(() => {
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/consultaLogin", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    }).then((response) => {
      if (response.data.loggedIn === true) {
      //  setTipoUser(response.data.user[0].tipo_usuario);
        setLoadingAdm(false);
      } else {
        //setTipoUser("8");
        setLoadingAdm(false);
      }
    });
  }, []);

  if (LoadingAdm) {
    return <Route {...props}></Route>;
  }
};

export default SolicitanteRoute;
