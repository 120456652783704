import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import parse from "html-react-parser";
import { formatDate } from "../../utils/date";
import SidebarSolicitante from "../../components/sidebar_solicitante/Sidebar_Solicitante";

function DetalhesConsultoria() {
  const location = useLocation();
  const [textSolicitado, setTextSolicitado] = useState("");
  const [textFarma, setTextFarma] = useState("");
  const [setSolicitante] = useState("");
  const [farmaceutico, setFarmaceutico] = useState("");
  const [data, setData] = useState("");
  const [area, setArea] = useState("");
  const [status, setStatus] = useState("");
  const [id] = useState(location.state.detail);

  const carregaDados = useCallback(() => {
    Axios.defaults.withCredentials = false;
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/buscarTelec", {
      protocolo: id,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setTextSolicitado(response.data[0].assunto);
        if (response.data[0].resposta == null) {
          setTextFarma("");
        } else {
          setTextFarma(response.data[0].resposta);
        }
        setData(response.data[0].data_hora);
        setArea(response.data[0].area_interesse);
        setFarmaceutico(response.data[0].nome_farmaceutico);
        setSolicitante(response.data[0].nome);
        setStatus(response.data[0].status);
      }
    });
  }, [id, setSolicitante]);

  useEffect(() => {
    carregaDados();
  }, [carregaDados]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <SidebarSolicitante>
        <p style={{ paddingTop: "1%", paddingBottom: "3%" }}>
          Detalhes de solicitação
        </p>
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            height: "20%",
            overflowY: "scroll",
          }}
        >
          {parse(textSolicitado)}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            paddingTop: "30px",
            alignItems: "flex-end",
          }}
        >
          <p style={{ marginLeft: "5%" }}>
            Área: <b>{area}</b>
          </p>
          <p style={{ marginLeft: "5%" }}>
            Status: <b>{status}</b>
          </p>
          <p style={{ marginLeft: "5%" }}>
            Data: <b>{formatDate(data)}</b>
          </p>
        </div>
        <h1
          className="titulo"
          style={{ paddingTop: "0.5%", paddingBottom: "1%" }}
        >
          Resposta farmacêutico
        </h1>
        <div
          style={{
            width: "100%",
            backgroundColor: "white",
            height: "20%",
            overflowY: "scroll",
          }}
        >
          {parse(textFarma)}
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            paddingTop: "30px",
            alignItems: "flex-end",
          }}
        >
          <p style={{}}>
            Farmacêutico: <b>{farmaceutico}</b>
          </p>
        </div>
      </SidebarSolicitante>
    </div>
  );
}

export default DetalhesConsultoria;