import React, { useEffect, useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Inject,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import Axios from "axios";

const Agenda = (props) => {
  Axios.defaults.withCredentials = true;

  const [data, setData] = useState("");
  const [origem, setOrigem] = useState("");
  const [status, setStatus] = useState("");
  const [listaFarmaceutico, setListaFarmaceutico] = useState([]);
  const [listaNutricao, setListaNutricao] = useState([]);
  const [listaPsicologia, setListaPsicologia] = useState([]);
  const [farmaceutico, setFarmaceutico] = useState("");
  const [nutricao, setNutricao] = useState("");
  const [psicologia, setPsicologia] = useState("");
  const [area, setArea] = useState("");
  const [listaTeleAssistencia, setListaTeleAssistencia] = useState([]);

  useEffect(() => {
    Axios.defaults.withCredentials = false;
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/retornaAgenda").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setData(response.data);
      setListaTeleAssistencia(response.data);
    });

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/lista_farmaceuticos").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      //console.log(response.data[0]);
      setListaFarmaceutico(response.data);
    });
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/lista_nutricao").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      //console.log(response.data[0]);
      setListaNutricao(response.data);
    });
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/lista_psicologia").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      //console.log(response.data[0]);
      setListaPsicologia(response.data);
    });
  }, []);

  const fields = {
    id: "id",
    subject: { name: "nome", title: "Compromisso", default: "Não Informado" },
    location: { name: "link_agenda", title: "Sala", default: "Não Informado" },
    description: { name: "codigo_verificador", title: "Responsável" },
    startTime: { name: "data_inicio", title: "Começa" },
    endTime: { name: "data_fim", title: "Termina" },
  };

  const filtros = () => {
    let temp = [...listaTeleAssistencia];
    if (origem != "") {
      const results = temp.filter((item) => {
        return item.origem === origem;
      });
      temp = [...results];
    }
    if (area != "") {
      const results = temp.filter((item) => {
        switch (area) {
          case "0":
            return item.id_farmaceutico !== null;
          case "1":
            return item.id_nutricao !== null;
          case "2":
            return item.id_psicologo !== null;
        }
      });
      temp = [...results];
    }
    if (status != "") {
      const results = temp.filter((item) => {
        return item.status === status;
      });
      temp = [...results];
    }
    if (farmaceutico != "") {
      const results = temp.filter((item) => {
        return item.id_farmaceutico == farmaceutico;
      });
      temp = [...results];
    }
    if (nutricao != "") {
      const results = temp.filter((item) => {
        return item.id_nutricao == nutricao;
      });
      temp = [...results];
    }
    if (psicologia != "") {
      const results = temp.filter((item) => {
        return item.id_psicologo == psicologia;
      });
      temp = [...results];
    }
    setData(temp);
  };

  return (
    <>
      <SidebarAdministrativo>
        <div class="subtitulo2">
          <h3>Filtros </h3>
        </div>
        <div class="div2">
          <div className="item">
            <p>Origem: </p>
            <select
              value={origem}
              onChange={(e) => {
                setOrigem(e.target.value);
              }}
            >
              <option></option>
              <option>PROTRAC</option>
              <option>Privado</option>
              <option>SUS</option>
              <option>Plano de Saúde</option>
              <option>Eldorado</option>
              <option>Demedia</option>
              <option>HPS</option>
              <option>Outro</option>
            </select>
          </div>
          <div className="item">
            <p>Status: </p>
            <select
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option></option>
              <option>Aberta</option>
              <option>Encerrada</option>
              <option>Acompanhamento</option>
              <option>Desmarcado</option>
            </select>
          </div>
          <div className="item">
            <p>Área: </p>
            <select
              value={area}
              onChange={(e) => {
                setArea(e.target.value);
              }}
            >
              <option></option>
              <option value="0">Farmácia</option>
              <option value="1">Nutrição</option>
              <option value="2">Psicologia</option>
            </select>
          </div>
          <div className="item">
            <p>Farmacêutico: </p>
            <select
              value={farmaceutico}
              onChange={(e) => {
                setFarmaceutico(e.target.value);
              }}
            >
              <option value=""></option>
              {listaFarmaceutico.map((option) => (
                <option value={option.id}>{option.nome}</option>
              ))}
            </select>
          </div>
          <div className="item">
            <p>Nutrição: </p>
            <select
              value={nutricao}
              onChange={(e) => {
                setNutricao(e.target.value);
              }}
            >
              <option value=""></option>
              {listaNutricao.map((option) => (
                <option value={option.id}>{option.nome}</option>
              ))}
            </select>
          </div>
          <div className="item">
            <p>Psicologo: </p>
            <select
              value={psicologia}
              onChange={(e) => {
                setPsicologia(e.target.value);
              }}
            >
              <option value=""></option>
              {listaPsicologia.map((option) => (
                <option value={option.id}>{option.nome}</option>
              ))}
            </select>
          </div>
          <div class="item">
            <p></p>
            <button onClick={filtros}>SELECIONAR</button>
          </div>
        </div>
        <ScheduleComponent eventSettings={{ dataSource: data, fields: fields }}>
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" />
            <ViewDirective option="Month" />
          </ViewsDirective>
          <Inject services={[Day, Week, WorkWeek, Month]} />
        </ScheduleComponent>
      </SidebarAdministrativo>
    </>
  );
};

export default Agenda;
