import React, { useEffect, useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import Axios from "axios";
import TabelaAdesaoMedicamentos from "../../components/TabelaAdesaoMedicamentos/TabelaAdesaoMedicamentos";

const Dashboard = (props) => {
  const [teleAssitenciasHoje, setTeleAssitenciasHoje] = useState("0");
  const [teleConsultoriasAbertas, setTeleConsultoriasAbertas] = useState("0");

  useEffect(() => {
    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/qtdTelecAbertas").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setTeleConsultoriasAbertas(response.data[0].quantidade);
    });

    Axios.get("https://atendimento.mdhmlg.vsoares.dev.br:3002/teleAssistenciaHoje").then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      }
      setTeleAssitenciasHoje(response.data[0].quantidade);
    });
  }, []);

  return (
    <>
      <SidebarAdministrativo>
        <div class="main__container">
          <div class="main__title">
            <div class="main__greeting">
              <h1>Bem vindo ao dashboard!</h1>
              <p>Setor Administrativo</p>
            </div>
          </div>

          <div class="main__cards">
            <div class="card">
              <div class="card_inner">
                <p class="text-primary-p">Teleconsultas Hoje</p>
                <span class="font-bold text-title">{teleAssitenciasHoje}</span>
                <i class="fa fa-calendar fa-2x text-red" aria-hidden="true"></i>
              </div>
            </div>

            <div class="card">
              <div class="card_inner">
                <p class="text-primary-p">Dado 3</p>
                <span class="font-bold text-title">0</span>
                <i class="fa fa-video-camera fa-2x text-yellow" aria-hidden="true"></i>
              </div>
            </div>

            <div class="card">
              <div class="card_inner">
                <p class="text-primary-p">Dado 4</p>
                <span class="font-bold text-title">0</span>
                <i class="fa fa-thumbs-up fa-2x text-green" aria-hidden="true"></i>
              </div>
            </div>
          </div>

          <div class="charts">
          <div class="charts__left">
              <div class="charts__left__title">
                <div>
                  <TabelaAdesaoMedicamentos/>
                </div>
                
              </div>
              <div id="apex1"></div>
            </div>

            <div class="charts__right">
              <div class="charts__right__title">
                <div>
                  <h1>Outros dados</h1>
                  <p>Importantes</p>
                </div>
                <i class="fa fa-question" aria-hidden="true"></i>
              </div>

              <div class="charts__right__cards">
                <div class="card1">
                  <h1>Dado</h1>
                  <p>0</p>
                </div>

                <div class="card2">
                  <h1>Dado</h1>
                  <p>0</p>
                </div>

                <div class="card3">
                  <h1>Dado</h1>
                  <p>0</p>
                </div>

                <div class="card4">
                  <h1>Dado</h1>
                  <p>0</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SidebarAdministrativo>
    </>
  );
};

export default Dashboard;
