import Axios from 'axios';
import React, { useState } from 'react';
import { isValidCPF } from '../../utils/valida_cpf';
import InputMask from "react-input-mask";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import '../Login/login.css';
import './CadastroFarmaceutico.css';
import { useHistory } from "react-router";

const CadastroFarmaceutico = () => {
  let history = useHistory();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaConfirma, setSenhaConfirma] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cep, setCep] = useState("");
  const [uf, setUf] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [crf, setCrf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [logo, setLogo] = useState(null);
  const [openTerms, setOpenTerms] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [codigoConfirmacao, setCodigoConfirmacao] = useState("");

  const handleOpenTerms = () => setOpenTerms(true);
  const handleCloseTerms = () => setOpenTerms(false);
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const register = (e) => {
    e.preventDefault();
    const endereco = `${rua}, ${numero}, ${complemento}, ${bairro}, ${cidade}, ${uf}, ${cep}`;
    if (
      nome === "" ||
      email === "" ||
      senha === "" ||
      senhaConfirma === "" ||
      nascimento === "" ||
      cpf === "" ||
      rg === "" ||
      telefone === "" ||
      cep === "" ||
      uf === "" ||
      cidade === "" ||
      bairro === "" ||
      rua === "" ||
      numero === "" ||
      complemento === "" ||
      crf === ""
    ) {
      alert("Preencha todos os campos");
    } else {
      if (senha === senhaConfirma) {
        if (isValidCPF(cpf)) {
          handleOpenTerms();
        } else {
          alert("CPF inválido");
        }
      } else {
        alert("As senhas não coincidem");
      }
    }
  };

  const confirmarCadastro = () => {
    handleCloseTerms();
    /*
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/send-confirmation-email", { email })
      .then((response) => {
        handleOpenConfirmation();
      })
      .catch((error) => {
        alert("Erro ao enviar código de confirmação: " + error.message);
      }); */
      handleOpenConfirmation();

  };

  const handleBlur = () => {
    preencheCEP(cep);
  };

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    const isValid = (file) => {
      if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg') {
        return { valid: false, error: 'Por favor, selecione um arquivo de imagem no formato PNG, JPEG ou JPG.' };
      }
      if (file.size > 5 * 1024 * 1024) {
        return { valid: false, error: 'O arquivo de imagem deve ter no máximo 5 MB.' };
      }
      return { valid: true, error: '' };
    };

    const validationResult = isValid(file);

    if (!validationResult.valid) {
      alert(validationResult.error);
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setLogo(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const preencheCEP = () => {
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/preencheCEP", {
      headers: {
        "Access-Allow-Control-Origin": "http://localhost:3000",
      },
      cep: cep,
    }).then((response) => {
      if (response.data.message) {
        alert(response.data.message);
      } else {
        setCep(response.data.cep);
        setBairro(response.data.bairro);
        setRua(response.data.logradouro);
        setCidade(response.data.localidade);
        setUf(response.data.uf);
      }
    });
  };

  const verificarCodigo = () => {
   /* Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/verify-confirmation-code", {
      email,
      code: codigoConfirmacao,
      requestData: {
        email,
        username: nome,
        senha,
        cpf,
        crf,
        cnpj,
        rg,
        tipo_usuario: 1, // Tipo 1 para farmacêutico
        cep,
        rua,
        cidade,
        bairro,
        complemento,
        estado: uf,
        telefone,
        data_nasc: nascimento,
        data_cadastro: new Date().toISOString().split("T")[0],
      }
    })
    .then((response) => {

      Axios.post( "https://atendimento.mdhmlg.vsoares.dev.br:3002/cadastraFarmaceutico", {
          cpf: cpf,
          registro: crf,
          uf: uf,
        }).then((response) => { 
          alert("Código de confirmação verificado com sucesso.");
          history.push("/loginfarmaceutico");
        })

    })
    .catch((error) => {
      alert("Erro ao verificar o código de confirmação: " + error.message);
    }); */


    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/cadastroSolicitante", {
      username: email,
      password: senha,
      nome: nome, 
      cpf: cpf,
      cep: cep,
      rua: rua,
      cidade: cidade,
      bairro: bairro,
      estado: uf,
      complemento: complemento,
      telefone: telefone,
      dataNasc: nascimento,
      crf: crf,
      cnpj: cnpj,
      rg: rg
    }).then((response) => { 
          Axios.post( "https://atendimento.mdhmlg.vsoares.dev.br:3002/cadastraFarmaceutico", {
            cpf: cpf,
            registro: crf,
            uf: uf,
          }).then((response) => {alert("Código de confirmação verificado com sucesso.");
              history.push("/loginfarmaceutico"); 
            })
        })
        };

  return (
    <>
      <Container component="main" maxWidth="xl" sx={{ height: "100vh", display: "flex", justifyContent: "center" }}>
        <CssBaseline>
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h3">
              CADASTRAR FARMACÊUTICO
            </Typography>
            <Box component="form" noValidate sx={{ mb: 8, mt: 8, mx: 'auto' }}>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={10} sm={10} sx={{ textAlign: 'center'}}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img
                      src={logo || require('../../imagens/logoFundo.png')}
                      alt="Logo Preview"
                      className="resizeLogo"
                    />
                    <input
                      type="file"
                      name="logo"
                      accept="image/png,image/jpeg,image/jpg"
                      onChange={handleLogoUpload}
                      style={{ display: 'none' }}
                      id="logo-upload"
                    />
                    <label className="custom-file-upload" htmlFor="logo-upload">
                      Inserir Logo
                    </label>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="nome"
                    name="nome"
                    required
                    fullWidth
                    id="nome"
                    label="Nome"
                    autoFocus
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="email"
                    name="email"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="senha"
                    name="senha"
                    required
                    fullWidth
                    id="senha"
                    label="Senha"
                    type="password"
                    autoFocus
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="senhaConfirma"
                    name="senhaConfirma"
                    required
                    fullWidth
                    id="senhaConfirma"
                    label="Confirme sua senha"
                    type="password"
                    autoFocus
                    value={senhaConfirma}
                    onChange={(e) => setSenhaConfirma(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="bday"
                    name="nascimento"
                    required
                    fullWidth
                    id="nascimento"
                    label="Data de Nascimento"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoFocus
                    value={nascimento}
                    onChange={(e) => setNascimento(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="999.999.999-99"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    onBlur={handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        autoComplete="cpf"
                        name="cpf"
                        required
                        fullWidth
                        id="cpf"
                        label="CPF"
                        autoFocus
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="rg"
                    name="rg"
                    required
                    fullWidth
                    id="rg"
                    label="RG"
                    autoFocus
                    value={rg}
                    onChange={(e) => setRg(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="(99) 99999-9999"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        autoComplete="tel"
                        name="telefone"
                        required
                        fullWidth
                        id="telefone"
                        label="Telefone"
                        type="tel"
                        autoFocus
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="99999-999"
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                    onBlur={handleBlur}
                  >
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        autoComplete="cep"
                        name="cep"
                        required
                        fullWidth
                        id="cep"
                        label="CEP"
                        autoFocus
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ minWidth: "100%" }}>
                      <InputLabel id="Uf-label">UF</InputLabel>
                      <Select
                        labelId="Uf-label"
                        id="Uf"
                        autoComplete="UF"
                        label="UF"
                        autoWidth
                        required
                        fullWidth
                        autoFocus
                        value={uf}
                        onChange={(e) => setUf(e.target.value)}
                      >
                        <MenuItem value="AC">Acre</MenuItem>
                        <MenuItem value="AL">Alagoas</MenuItem>
                        <MenuItem value="AP">Amapá</MenuItem>
                        <MenuItem value="AM">Amazonas</MenuItem>
                        <MenuItem value="BA">Bahia</MenuItem>
                        <MenuItem value="CE">Ceará</MenuItem>
                        <MenuItem value="DF">Distrito Federal</MenuItem>
                        <MenuItem value="ES">Espírito Santo</MenuItem>
                        <MenuItem value="GO">Goiás</MenuItem>
                        <MenuItem value="MA">Maranhão</MenuItem>
                        <MenuItem value="MT">Mato Grosso</MenuItem>
                        <MenuItem value="MS">Mato Grosso do Sul</MenuItem>
                        <MenuItem value="MG">Minas Gerais</MenuItem>
                        <MenuItem value="PA">Pará</MenuItem>
                        <MenuItem value="PB">Paraíba</MenuItem>
                        <MenuItem value="PR">Paraná</MenuItem>
                        <MenuItem value="PE">Pernambuco</MenuItem>
                        <MenuItem value="PI">Piauí</MenuItem>
                        <MenuItem value="RJ">Rio de Janeiro</MenuItem>
                        <MenuItem value="RN">Rio Grande do Norte</MenuItem>
                        <MenuItem value="RS">Rio Grande do Sul</MenuItem>
                        <MenuItem value="RO">Rondônia</MenuItem>
                        <MenuItem value="RR">Roraima</MenuItem>
                        <MenuItem value="SC">Santa Catarina</MenuItem>
                        <MenuItem value="SP">São Paulo</MenuItem>
                        <MenuItem value="SE">Sergipe</MenuItem>
                        <MenuItem value="TO">Tocantins</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="cidade"
                      name="cidade"
                      required
                      fullWidth
                      id="cidade"
                      label="Cidade"
                      autoFocus
                      value={cidade}
                      onChange={(e) => setCidade(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="bairro"
                      name="bairro"
                      required
                      fullWidth
                      id="bairro"
                      label="Bairro"
                      autoFocus
                      value={bairro}
                      onChange={(e) => setBairro(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="rua"
                      name="rua"
                      required
                      fullWidth
                      id="rua"
                      label="Rua"
                      autoFocus
                      value={rua}
                      onChange={(e) => setRua(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="numero"
                      name="numero"
                      required
                      fullWidth
                      id="numero"
                      label="Número"
                      autoFocus
                      value={numero}
                      onChange={(e) => setNumero(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      autoComplete="complemento"
                      name="complemento"
                      required
                      fullWidth
                      id="complemento"
                      label="Complemento"
                      value={complemento}
                      autoFocus
                      onChange={(e) => setComplemento(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="crf"
                      name="crf"
                      required
                      fullWidth
                      id="crf"
                      label="CRF"
                      autoFocus
                      value={crf}
                      onChange={(e) => setCrf(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="cnpj"
                      name="cnpj"
                      required
                      fullWidth
                      id="cnpj"
                      label="CNPJ"
                      autoFocus
                      value={cnpj}
                      onChange={(e) => setCnpj(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} sx={{ mb: 2 }}>
                    <button
                      type="submit"
                      className="login100-form-btn"
                      onClick={register}
                    >
                      Cadastrar
                    </button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </CssBaseline>
        </Container>
    
        <Modal
          open={openTerms}
          onClose={handleCloseTerms}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="modal"
        >
          <Box className="modal-box" sx={{ minWidth: 800, minHeight: 500 }}>
            <Typography id="modal-title" variant="h6" component="h2">
              Termos de Adesão ao Serviço
            </Typography>
            <Typography id="modal-description" className="modal-description" sx={{ mt: 2, minWidth: 750 }}>
            <p>
            CONDIÇÕES GERAIS DA LICENÇA DE USO DA PLATAFORMA MYDIGICARE FARMA
O presente documento e seu ANEXO 1, contem as Condições Gerais da Licença de Uso da Plataforma da MyDIGICARE FARMA, de propriedade de MyDIGICARE PRESTAÇÃO DE SERVIÇOS FARMACÊUTICOS  LTDA, sociedade empresária constituída sob a forma de limitada, tendo sua matriz na cidade de Porto Alegre, no Estado do Rio Grande do Sul, na Av. Luiz Manoel Gonzaga, 187/ 1004, Petrópolis, CEP 90470-280, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da Fazenda sob o nº 36.160.501/0001-82, que tem por objetivo disponibilizar canal eletrônico de comunicação segura entre USUÁRIO PACIENTE e seu USUÁRIO FARMACÊUTICO, abaixo definidos.
Estas condições gerais da licença de uso da Plataforma MyDIGICARE FARMA devem ser lidas com atenção antes de sua utilização.
1. DAS DEFINIÇÕES
Para fins do presente, sem prejuízo das demais nomenclaturas nomeadas ao longo deste instrumento, os termos abaixo deverão ter o seguinte significado:
1.1. CONDIÇÕES GERAIS: estabelece o conjunto de deveres, obrigações e condições aplicáveis a todos os USUÁRIOS que utilizem a plataforma.
1.2. LOGIN: conjunto de caracteres alfanuméricos que identificam e distinguem o USUÁRIO na utilização da plataforma, sendo este de caráter pessoal e intransferível.
1.3. SENHA: conjunto de caracteres alfanuméricos que validam e autorizam o acesso do Usuário à plataforma, sendo este de caráter pessoal e intransferível.
1.4. PLATAFORMA: linha de produto ou sistema cuja licença para uso foi adquirida pelo Usuário. Trata-se de software, sistema de computador de propriedade da MyDIGICARE.
1.5. TERMO DE ADESÃO: documento eletrônico pelo qual o USUÁRIO e a MyDIGICARE FARMA formalizam a licença de uso da Plataforma, bem como o USUÁRIO manifesta expressamente sua concordância com as Condições Gerais.
1.6. USUÁRIO: qualquer pessoa que acessar o site ou que efetue o cadastro como USUÁRIO PACIENTE ou USUÁRIO FARMACÊUTICO.
1.7. USUÁRIO FARMACÊUTICO: pessoa física, terceiro independente na prestação de serviços de saúde, cadastrado como “USUÁRIO FARMACÊUTICO” na MyDIGICARE FARMA, devidamente inscrito e ativo em seu Conselho Regional de Farmácia (CRF), habilitado a atender chamadas de TELEATNDIMENTO com seu paciente, cuja certificação digital é imprescindível, a qual deverá ser realizada por Certificadora disponível no mercado.
1.8. USUÁRIO PACIENTE: pessoa física, capaz de realizar compra ou receber crédito de minutos para realizar TELEATENDIMENTO após consulta, com seu farmacêutico como “USUÁRIO FARMACÊUTICO” na MyDIGICARE FARMA.
2. CONCORDÂNCIA COM AS CONDIÇÕES GERAIS
2.1. O uso da Plataforma MyDIGICARE FARMA, ainda que parcialmente ou a título de teste, significará que o USUÁRIO está plenamente ciente de todas as disposições destas Condições Gerais, significando também plena e integral concordância com as cláusulas aqui apresentadas. 
2.2. Uma vez que o uso da Plataforma MyDIGICARE FARMA apenas pode se dar após a leitura destas Condições Gerais, sua utilização significará a concordância integral com os termos destas Condições Gerais. Caso o usuário não concorde com qualquer uma das disposições destas condições gerais, ele não deverá usar os sistemas da MyDIGICARE FARMA. Sendo assim, o uso da plataforma não está autorizado em caso de não concordância com estas condições gerais. 
2.3. Caso o USUÁRIO venha a discordar das Condições Gerais posteriormente ao início da utilização da Plataforma, o uso deste deverá ser imediatamente interrompido, sendo certo que estas Condições Gerais terão vigência durante todo o período de uso, não cabendo ao usuário alegar o desconhecimento destas Condições Gerais.
2.4. Os USUÁRIOS reconhecem e concordam que a MyDIGICARE FARMA não é prestadora de serviços de saúde, mas apenas fornecedora da plataforma que possibilita um canal eletrônico de comunicação seguro entre USUÁRIO PACIENTE e seu USUÁRIO FARMACÊUTICO, e que todos os serviços profissionais serão prestados por farmacêuticos independentes, livremente cadastrados em nossa plataforma, como USUÁRIO FARMACÊUTICO sem vínculo empregatício ou qualquer representação por parte da MyDIGICARE FARMA, ficando sob a responsabilidade exclusiva destes todas as informações relativas ao TELEATENDIMENTO.
2.5. Os USUÁRIOS entendem que este Instrumento tem a natureza jurídica de um contrato e concorda que o Aceite implicará na vinculação da MyDIGICARE FARMA e do USUÁRIO aos seus termos e condições. Dessa forma, recomenda-se que o USUÁRIO imprima uma cópia deste documento para futura referência.
3. LICENÇA DE USO
3.1. Estas Condições Gerais regulamentam a forma pela qual a MyDIGICARE FARMA concede ao USUÁRIO uma licença de uso da Plataforma, em caráter não exclusivo, não transferível e temporário, o qual deverá ser utilizado em conformidade com as presentes Condições Gerais.
3.2. A Plataforma da MyDIGICARE FARMA não é, em hipótese alguma, vendida para o USUÁRIO, e sim possui seu uso licenciado, de acordo com a legislação brasileira. Sua propriedade não será transferida a quem quer que seja sem o consentimento prévio, expresso e específico da MyDIGICARE. Assim sendo, é vedada a cessão e/ou comercialização da Plataforma, seja total ou parcialmente, a terceiros, sob pena de responsabilização por perdas e danos.
3.2.1. Por adquirir apenas uma licença de uso, o USUÁRIO, em nenhuma hipótese, terá acesso ao código fonte do software da Plataforma. 
3.3. O uso da Plataforma deverá observar estritamente todos os termos e disposições das presentes Condições Gerais, da legislação vigente, bem como normas e resoluções estabelecidas pelo respectivo órgão de classe. Em caso de uso não autorizado nestas condições gerais, o usuário estará sujeito a todas as penalidades previstas nestas condições gerais e na legislação, assim como ao pagamento das eventuais perdas e danos que a MyDIGICARE FARMA vier a sofrer.
3.4. O uso da Plataforma está condicionado a uma conexão de internet ativa do usuário, sendo que a MyDIGICARE FARMA não se responsabiliza pela prestação de serviços de acesso da internet.
3.4.1. A MyDIGICARE FARMA não se responsabilizará por impossibilidade de utilização da Plataforma em caso de falta de conexão com a internet. 
3.5. Para utilizar a Plataforma, o Usuário deverá sempre possuir um Login e uma Senha válidos, sem os quais a Plataforma não poderá ser utilizada.
3.5.1. O USUÁRIO, ao adquirir ou se cadastrar para utilização da licença de uso da Plataforma, receberá um Login e uma Senha com poderes de administrador, cuja utilização é de sua única e exclusiva responsabilidade.
3.5.2 O USUÁRIO contratante, administrador da plataforma, tem poderes de adicionar e editar credenciais de acesso para novos usuários, sejam, secretária ou outros perfis. É de responsabilidade exclusiva dos USUÁRIOS com perfil de administrador a gestão e revisão continuada das credenciais de acesso ao sistema, garantindo permissão de entrada apenas para usuários aprovados por ele. A MyDIGICARE FARMA pode cobrar valores adicionais em razão da inclusão de novos usuários.
3.5.3 Todos os USUÁRIOS com acesso ao sistema, sejam administradores ou não, cadastrados pela MyDIGICARE FARMA ou pelo administrador, são responsáveis pelas suas próprias credenciais de acesso (login e senha), não cabendo à MyDIGICARE FARMA a responsabilidade pelos atos, práticas, cadastros feitos ou alterados, por qualquer um dos usuários. Dada a suspeita de perda de senha ou acesso indevido por terceiros, o USUÁRIO deverá comunicar a MyDIGICARE FARMA imediatamente. 
3.5.4. A senha do usuário, por motivos de segurança, é criptografada, o que enseja a impossibilidade de sua recuperação em caso de perda. Neste caso, o USUÁRIO terá sua senha resetada, recebendo uma nova senha. 
3.6. A MyDIGICARE FARMA poderá conceder ao USUÁRIO uma licença de uso gratuita, para testes de Software. Nessa situação, os direitos de uso da Plataforma estarão limitados ao período de testes da Plataforma, período esse cuja duração será devidamente informada ao Usuário. Após o término do período de testes, a MyDIGICARE FARMA, sem necessidade de aviso prévio, bloqueará o login e a senha do Usuário e este deverá descontinuar o uso da Plataforma.  
3.6.1. Ainda após o término do período de testes, o USUÁRIO poderá continuar o uso da Plataforma adquirindo da MyDIGICARE FARMA uma licença não gratuita de uso da Plataforma, iniciando o período de contratação da licença. 
3.6.2. Caso o USUÁRIO requeira a liberação de USUÁRIO complementar para uso da Plataforma, aquele ficará condicionado ao pagamento de valor específico para tal fim.
3.7. A plataforma da MyDIGICARE FARMA é formada por um conjunto de módulos funcionais em formato de software ou sistemas, tais como Agenda, Prontuário Eletrônico, Pagamento, Pacientes, dentre outros. Esses módulos podem ser utilizados pelo farmacêutico, de acordo com a escolha do Usuário, que poderá adquirir um ou mais módulos em conjunto.
3.7.1. A aquisição da licença de uso da Plataforma não significará a licença de uso de todos os módulos da Plataforma MyDIGICARE FARMA.
3.7.2. O Usuário adquirirá apenas as licenças dos módulos da Plataforma MyDIGICARE FARMA que ele solicitar na ocasião de sua aquisição.
3.7.3. O valor devido pelo Usuário à MyDIGICARE FARMA pela licença de uso da Plataforma irá variar de acordo com o número e os tipos de módulos solicitados pelo USUÁRIO, bem como de acordo com o número de usuários ativos na plataforma.
3.8. O Usuário Farmacêutico, administrador da Plataforma, se dá por ciente no momento de aceite destas Condições Gerais, que as informações incluídas através do login de administrador ou do login de usuários autorizados por aquele, poderão ser acessadas pelo paciente, desde que o paciente tenha acesso ao site da MyDIGICARE FARMA, e, portanto, aceitado as condições gerais destinadas ao acesso de paciente.
3.8.1. O USUÁRIO FARMACÊUTICO, ao inserir o CPF e Número do celular ao campo de cadastro do Paciente, fica ciente que automaticamente permitirá acesso do respectivo USUÁRIO PACIENTE, onde este terá acesso aos seus dados pessoais, de Agendamentos, Consultas e outros.
3.9. Informações de Saúde, como Prontuário, Receitas, Exames e Atestados, podem ser compartilhados ou não pelo USUÁRIO FARMACÊUTICO para outro profissional de saúde, mediante autorização do paciente. É responsabilidade do USUÁRIO FARMACÊUTICO, e direito do USUÁRIO PACIENTE, decidir se deseja ou não possibilitar acesso as informações de saúde contidas na Plataforma.
4. CONDIÇÕES COMERCIAIS
4.1. Pela licença da Plataforma, os USUÁRIOS pagarão à MyDIGICARE FARMA os valores definidos na ocasião da aquisição da licença de uso.
4.2. A aquisição da licença de uso poderá se dar diretamente por meio do website, WhatsApp, e-mail ou da Plataforma MyDIGICARE FARMA, ou qualquer outro meio.
4.2.1. Nesse caso, os USUÁRIOS serão cientificados das opções de condições comerciais para aquisição da licença de uso da Plataforma, devendo optar por uma delas.
4.2.2. Além disso, o USUÁRIO deverá fornecer à MyDIGICARE FARMA seus dados pessoais, tais como, nome completo, endereço, número de inscrição no Cadastro de Pessoas Físicas ou Jurídicas e demais dados necessários à sua correta identificação, incluindo a documentação funcional do órgão de classe (CRF).  
4.2.3. Escolhidas as condições comerciais e efetuado o cadastro, será solicitado o aceite do Usuário no Termo de Adesão no primeiro acesso aos sistemas da Plataforma MyDIGICARE FARMA. 
4.3. Em caso de necessidade de deslocamento de prepostos da MyDIGICARE FARMA até a localidade do usuário, este estará sujeito ao pagamento do reembolso de despesas com passagens, refeições e hospedagem, ou qualquer outra que seja necessária para o atendimento.
4.3.1. A estimativa de custos decorrentes do deslocamento será previamente informada aos USUÁRIOS, ficando este obrigado ao pagamento caso, após cientificado da estimativa, aceite o deslocamento.
4.4. Na ocasião da aquisição da licença de uso, os USUÁRIOS serão informados da forma de pagamento e das datas de vencimento.
4.4.1. Havendo atraso em qualquer dos pagamentos devidos pelo usuário à MyDIGICARE FARMA, o valor devido será monetariamente corrigido pelo IGPM-FGV, com acréscimo de multa moratória de 2% (dois por cento) e juros legais de 1% (um por cento) ao mês, calculados pro rata die.
4.5. Os valores devidos pelos USUÁRIOS à MyDIGICARE FARMA serão anualmente reajustados com base na variação do IGPM-FGV verificada no período.
4.6. Em caso de atraso, inadimplemento ou mora em qualquer pagamento devido pelo usuário à MyDIGICARE FARMA, a MyDIGICARE poderá suspender a licença de uso da Plataforma até que a dívida seja quitada pelo usuário.
4.6.1. A suspensão da licença de uso poderá se dar por meio do simples bloqueio do Login e/ou Senha do Usuário.
4.7. Em caso de atraso ou mora no pagamento por parte do usuário inadimplente que tenha excepcionalmente, por mera liberalidade, utilizado os serviços oferecidos pela MyDIGICARE FARMA sem que houvesse feito pagamento pelo período de disponibilização, poderão ser extraídos e/ou emitidos contra o USUÁRIO documentos e/ou títulos de créditos para a cobrança da dívida, inclusive sob a forma de duplicata, podendo a MyDIGICARE FARMA, inclusive, levar tais títulos e/ou documentos a protesto, na forma da lei.
4.7.1. O valor total de inadimplência do Usuário terá como critério o período total de utilização dos serviços disponibilizados pela MyDIGICARE FARMA sem a realização do seu efetivo pagamento, sendo facultado a, MyDIGICARE a aplicação de multa de 2% (dois por cento) acrescido de juros de 1% (um por cento) ao mês para fins de cobrança. 
5. REQUISITOS MÍNIMOS PARA UTILIZAÇÃO DA PLATAFORMA
5.1. Para utilizara Plataforma, os USUÁRIOS deverão atender as exigências mínimas de configuração de equipamento (hardware), de sistema operacional (software), devendo ainda possuir uma conexão ativa com a Internet.
5.2. A exigência para utilização da Plataforma é a disponibilidade de conexão com a internet, bem como a utilização de um navegador (browser) ou aplicativos.
5.3. O USUÁRIO está obrigado a utilizar a Plataforma respeitando e observando estas Condições Gerais de Uso, bem como a legislação vigente, os costumes e a ordem pública. Desta forma, o USUÁRIO concorda que não poderá:
(i) lesar direitos de terceiros, independentemente de sua natureza, em qualquer momento, inclusive no decorrer do uso da Plataforma;
(ii) executar atos que limitem ou impeçam o acesso e a utilização da Plataforma, em condições adequadas, aos demais USUÁRIOS;
(iii) acessar ilicitamente a Plataforma ou sistemas informáticos de terceiros relacionados a Plataforma ou à MyDIGICARE FARMA sob qualquer meio ou forma;
(iv) difundir programas ou vírus informáticos suscetíveis de causar danos de qualquer natureza, inclusive em equipamentos e sistemas da MyDIGICARE ou de terceiros;
(v) utilizar mecanismos que não os expressamente habilitados ou recomendados na Plataforma para obtenção de informações, conteúdos e serviços;
(vi) realizar quaisquer atos que de alguma forma possam implicar qualquer prejuízo ou dano à MyDIGICARE FARMA ou a outros USUÁRIOS;
(vii) acessar áreas de programação da Plataforma, bases de dados ou qualquer outro conjunto de informações que escape às áreas públicas ou restritas da Plataforma;
(viii) realizar ou permitir engenharia reversa, traduzir, modificar, alterar a linguagem, compilar, decompilar, modificar, reproduzir, alugar, sublocar, divulgar, transmitir, distribuir, usar ou, de outra maneira, dispor da Plataforma ou das ferramentas e funcionalidades nele disponibilizadas sob qualquer meio ou forma, inclusive de modo a violar direitos da MyDIGICARE (inclusive de Propriedade Intelectual) e/ou de terceiros;
(ix) interferir na segurança ou cometer usos indevidos contra a Plataforma ou qualquer recurso do sistema, rede ou serviço conectado ou que possa ser acessado por meio da Plataforma, devendo acessar a Plataforma e apenas para fins lícitos e autorizados;
(x) utilizar o domínio da MyDIGICARE para criar links ou atalhos a serem disponibilizados em e-mails não solicitados (mensagens spam) ou em websites de terceiros ou do próprio USUÁRIO ou, ainda, para realizar qualquer tipo de ação que possa vir a prejudicar a MyDIGICARE FARMA ou terceiros;
(xi) utilizar aplicativos automatizados de coleta e seleção de dados para realizar operações massificadas ou para quaisquer finalidades ou, ainda, para coletar e transferir quaisquer dados que possam ser extraídos da Plataforma para fins não permitidos ou ilícitos,
(xii) utilizar as ferramentas e funcionalidades da Plataforma para difundir mensagens não relacionadas com a Plataforma ou com as finalidades da Plataforma, incluindo mensagens de cunho racista, étnico, político, religioso, cultural ou depreciativo, difamatório e/ou calunioso de qualquer pessoa ou grupo social.
6. SUPORTE TÉCNICO E TREINAMENTO
6.1. A MyDIGICARE dará suporte técnico relativo à Plataforma remotamente, por telefone e pela internet, no horário comercial, isto é, das 08h00min às 18h00min, em dias úteis, isto é, de segunda à sexta-feira, excluídos vésperas de feriados e feriados.
6.2. Para os fins destas Condições Gerais, serão considerados os dias úteis do Município de Porto Alegre.
6.3. Eventuais atendimentos solicitados fora do horário padrão de atendimento, isto é, dias úteis, das 08h00min às 18h00min, estarão sujeitos à disponibilidade da MyDIGICARE e ao pagamento de quantia adicional, a ser devidamente informada na ocasião da solicitação do serviço.
6.4. O serviço de suporte técnico consistirá em técnicos treinados para solucionar dúvidas e orientar o Usuário na operação da Plataforma, que efetuarão, dentre outras atividades:
6.4.1. Suporte remoto;
6.4.2. Diagnóstico e solução remota de problemas;
6.4.3. Troca de mensagens entre operadores.
6.5. Poderá existir limite máximo de horas de suporte a serem utilizadas pelo Usuário, o que será definido no fechamento das condições comerciais na ocasião da aquisição da licença de uso da Plataforma.
6.6. Nos serviços de suporte não estão inclusos, não sendo prestados pela MyDIGICARE ou sendo cobrados à parte, conforme avaliação do problema apresentado pelo usuário:
6.6.1. Recuperação de arquivos de dados, quando possíveis, provocados por erros de operação, falhas de equipamentos, sistema operacional, instalação elétrica, etc.;
6.6.2. Correções de erros provenientes de operação e uso indevido da Plataforma;
6.6.3. Serviços de consultoria gerencial/administrativa relacionada à atividade empresarial do Usuário;
6.6.4. Serviços de migração e conversão de dados de ou para outros equipamentos ou softwares;
6.6.5. Qualquer problema de hardware ou de qualquer dispositivo conectado à Plataforma.
6.7. A MyDIGICARE poderá, a seu exclusivo critério, prestar o serviço de suporte técnico no local de uso da Plataforma, sendo que, por esse serviço extraordinário, a MyDIGICARE fará prévio orçamento do valor que será devido pelo usuário, além de correr por conta do usuário todas as despesas efetuadas relativas a transporte de pessoal técnico, alimentação e estada.
6.8. O treinamento de uso da Plataforma será todo feito através de módulos gravados e/ ou por Teleconferência em horário e dia especificados, e disponibilizados aos USUÁRIOS em uma Plataforma de Ensino à Distância – EAD.
7. ATUALIZAÇÃO
7.1. A Plataforma, embora com garantia de bom funcionamento, está sujeita a constantes modificações, decorrentes de otimizações e aperfeiçoamentos, que visam facilitar e tornar mais segura e eficiente a sua operação, melhor aproveitar os recursos computacionais dos equipamentos, bem como efetuar as alterações devidas às modificações de legislação.
7.2. O USUÁRIO, enquanto durar sua licença de uso, terá direito a receber as novas versões de Software da Plataforma no que diz respeito às atualizações, no entanto, não terá direito a novos módulos, que porventura vierem a ser ofertados pela MyDIGICARE, exceto se o Usuário desejar comprá-los e incorporá-los aos módulos que já possui.
7.3. Os sistemas objeto deste Contrato é de uso genérico. Embora direcionado para o segmento comercial do USUÁRIO, pode não contemplar todas as suas particularidades operacionais.
7.4. Caso o Usuário necessite de modificações na Plataforma para que este atenda melhor suas necessidades, ele deverá solicitá-las à MyDIGICARE, que analisará o pedido e sua viabilidade técnica. Eventualmente, a MyDIGICARE poderá atender a solicitação mediante pagamento, devendo efetuar, nesse caso, a proposta comercial ao Usuário, que conterá, no mínimo, o valor e o prazo de conclusão do serviço solicitado.
7.5. Todas as modificações, melhorias e correções efetuadas na Plataforma, mesmo que informadas, solicitadas, e eventualmente pagas pelo usuário, serão de propriedade da MyDIGICARE e ficarão incorporadas à Plataforma e sujeitas aos termos destas condições gerais, podendo inclusive ser disponibilizadas pela MyDIGICARE a outros usuários. Em nenhuma hipótese, o usuário terá a propriedade de qualquer modificação que venha a ser introduzida na Plataforma, ainda que solicitada e eventualmente paga pelo usuário. Toda e qualquer modificação, melhoria ou correção efetuada na Plataforma será objeto de licença de uso, sujeita aos mesmos termos e disposições destas condições gerais. 
7.6. O USUÁRIO desde já concorda com as modificações, melhorias ou correções no modelo de Software que utiliza, ficando a critério da MyDIGICARE o gerenciamento e implementação dessas atualizações. A instalação das atualizações é feita pela MyDIGICARE de forma automática na Plataforma.
8. SIGILO E CONFIDENCIALIDADE
8.1. O USUÁRIO está ciente de que as informações por ele inseridas na Plataforma ficarão sob a guarda de um servidor da Empresa Hostgator ou outro servidor de hospedagem que garanta a mesma qualidade de serviço contratado, sendo que a MyDIGICARE garante que tais dados são protegidos de forma razoável contra acesso não autorizado.
8.2. A MyDIGICARE toma todas as medidas de segurança adequadas para se proteger contra acesso, alteração, divulgação ou destruição não autorizada dos dados por ela armazenados. Essas medidas incluem análises internas de suas práticas de coleta, armazenamento e processamento de dados e medidas de segurança, incluindo criptografia e medidas de segurança física apropriadas para nos proteger contra o acesso não autorizado a sistemas em que armazenamos os dados pessoais.
8.3. A MyDIGICARE garante a realização de backup de todos os bancos de dados diariamente através da Hostgator ou serviço de hospedagem similar, entretanto, apenas estará disponível as atualizações feitas nos últimos 07 dias antes da solicitação. Em caso de necessidade de utilização dos backups, é solicitado um prazo de até 72 (setenta e duas) horas para os trâmites de recuperação, processamento e ativação dos sistemas com os dados do backup e consequente disponibilização para o usuário.
8.4. O USUÁRIO autoriza, previamente, o acesso pela MyDIGICARE às informações inseridas na Plataforma de forma anonimizada para análise de dados. Tal autorização não acarretará perda ou fragilidade no sigilo das informações de pacientes sob os cuidados do Usuário, uma vez que os dados estarão anonimizados e não terão relação direta com pacientes específicos, mas sim de dados qualitativos e quantitativos de população e suas características.
8.5. Em caso de necessidade de utilização de backup, resta autorizado à MyDIGICARE o acesso às informações clínicas e de saúde dos Usuários, bem como de seus pacientes/ clientes e funcionários, sem o qual a MyDIGICARE não terá como processar a recuperação das informações.
8.6. Resta autorizado à MyDIGICARE o acesso às informações para o objetivo de emissão de relatórios, gratuitos ou não, solicitados pelo Usuário, em caráter de prestação de serviços de consultoria em gestão empresarial, gestão de risco, análise de dados e business intelligence.
8.7 Todo paciente do Usuário e/ou de sua equipe, terá acesso aos seus dados através da plataforma MyDIGICARE do Paciente, sendo possível que seus dados pessoais e dados de agendamento de suas consultas lhes sejam compartilhados em até 24h apó a solicitação. 
8.8 Caso deseje ou seja requisitado pelo Paciente, dados de saúde, como informações do prontuário, receitas, prescrições e atestados, também podem ser compartilhados com o Paciente através de iniciativa manual e de livre vontade do Usuário.
8.9. A MyDIGICARE, ainda, dispõe de uma Política específica para regular a coleta, guarda e utilização de dados pessoais, bem como a sua segurança: Política de Privacidade e Segurança. Essa Política específica integra inseparavelmente estes Termos e Condições de Uso, ressaltando-se que os dados de utilização da Plataforma serão arquivados nos termos da legislação em vigor.
9. VIGÊNCIA E RESCISÃO
9.1. A licença de uso objeto destas Condições Gerais terá a duração definida nas condições comerciais acertadas entre o Usuário e a MyDIGICARE na ocasião da aquisição da licença de uso da Plataforma.
9.2. Em caso de licença de uso por prazo indeterminado, o USUÁRIO poderá solicitar a qualquer tempo, e sem a incidência de qualquer ônus ou penalidade, a rescisão deste Contrato, devendo, no entanto, ser respeitado o aviso prévio de 30 (trinta) dias.
9.3. A MyDIGICARE poderá cancelar a licença de uso do USUÁRIO, independentemente de notificação ou interpelação judicial ou extrajudicial da outra parte, caso ocorra quaisquer das hipóteses abaixo relacionadas:
9.3.1. Falência, recuperação judicial ou dissolução de qualquer das partes requerida, decretada ou homologada;
9.3.2. Quando o Usuário descumprir quaisquer dispositivos destas Condições Gerais, salvo se sanado o inadimplemento em até 05 (cinco) dias, contados da notificação escrita, via e-mail, enviada ao Usuário informando do descumprimento;
9.3.3. Quando o USUÁRIO atrasar o pagamento de qualquer quantia por ele devida à MyDIGICARE por período superior a 5 (cinco) dias, consecutivos, a MyDIGICARE poderá suspender o uso da Plataforma, bloqueando os respectivos logins de acesso, só sendo liberado após a comprovação do pagamento.
9.3.4. Quando o USUÁRIO atrasar o pagamento de qualquer quantia por ele devida à MyDIGICARE por período superior a 30 (trinta) dias, consecutivos ou não, a MyDIGICARE poderá rescindir sem comunicação prévia. 
9.4. Caso o USUÁRIO tenha adquirido a licença de uso da Plataforma sem ter usufruído do período de testes, ele poderá, no prazo de 07 (sete) dias corridos contados a partir da data de aquisição da licença, rescindir o Contrato sem a incidência de qualquer ônus ou penalidade, fazendo jus à devolução integral de toda e qualquer quantia paga à MyDIGICARE, sem a incidência de encargos financeiros de qualquer natureza, especialmente juros, multa e correção monetária.
9.5. A MyDIGICARE não emite notas fiscais referentes aos valores das consultas realizadas pelos farmacêuticos que contratarem os serviços MyDIGICARE FARMA
10. OBRIGAÇÕES DO USUÁRIO
São obrigações do Usuário, sem prejuízo das demais previstas nestas Condições Gerais e na legislação pertinente:
10.1. Utilizar a Plataforma exclusivamente ao fim a que ele se destina, abstendo-se de trafegar e armazenar dados e informações que importem em prejuízo a terceiros; violem a lei, a moral, os bons costumes; a propriedade intelectual; que incorporem vírus ou outros elementos físicos ou eletrônicos que possam danificar ou impedir o uso normal da Plataforma;
10.2. Utilizar somente equipamentos hábeis e capazes de atender às exigências mínimas de configuração necessárias à utilização da Plataforma, bem como pelo manuseio correto do programa;
10.3. Pagar pontualmente os valores relativos à Licença de Uso, sob pena de incidência de encargos moratórios, suspensão da cessão da licença e rescisão contratual;
10.4. Não acessar, desconstituir, alterar, modificar, seccionar ou de qualquer forma adulterar os códigos fonte de software da Plataforma, nem copiar e/ou transferir o software cuja licença de uso lhe é cedida, mediante contratação (pirataria);
10.5. Guardar em local seguro, inviabilizando a não divulgação ou, de qualquer modo, o acesso aos elementos de autenticação do usuário da Plataforma, seja pelo Login e pela Senha;
10.6. Manter seu computador ou dispositivo móvel livre de vírus ou similares, propiciando assim maior segurança ao tráfego e armazenamento dos dados/informações;
10.7. Manter os seus dados cadastrais devidamente atualizados, sob pena de, em não o fazendo, considerarem-se válidos todos os avisos e notificações enviados para os endereços constantes do referido cadastro, incluindo os dados profissionais dos respectivos conselhos de classe, devendo ainda comunicar de imediato, fatos impeditivos do regular exercício da profissão;
10.8. Autorizar que a MyDIGICARE proceda as necessárias modificações, reparações e implementação de novos recursos à Plataforma, caso este apresente alguma anomalia ou irregularidade, de modo a torná-lo mais seguro, eficiente e eficaz, mesmo que acarrete sua indisponibilidade temporária;
10.9. Reconhecer que todas as modificações, melhorias e correções efetuadas na Plataforma, mesmo que informadas, solicitadas, e eventualmente pagas pelo Usuário, ficam incorporadas aos softwares, e serão de propriedade da MyDIGICARE, sendo, portanto, objeto de licença de uso, nos mesmos termos e sujeitando-se às presentes Condições Gerais, podendo inclusive ser disponibilizadas pela MyDIGICARE a outros Usuários que utilizem a Plataforma;
10.10. Eximir-se de, por si ou por terceiros, traduzir, fazer engenharia reversa, descompilar, copiar imagens, códigos ou quaisquer partes do software para utilização fora dele, modificar o software ou mesclar todas ou qualquer de suas partes com outro programa, remover ou alterar qualquer aviso de copyright, marca registrada, ou outro aviso de direitos de propriedade colocados na Plataforma ou em parte do mesmo.
11. GARANTIA E RESPONSABILIDADES
11.1. A MyDIGICARE assegura a funcionalidade da Plataforma, desde que devidamente utilizado pelo Usuário de acordo com as instruções da MyDIGICARE, pelo período que durar a licença de uso contratada pelo Usuário.
11.2. A mencionada garantia não será aplicável nos casos em que qualquer não conformidade ou defeito for exclusivamente causado por:
11.2.1. Qualquer programa de computador associado ou complementar cujo uso conjunto não tenha sido expressamente autorizado pela MyDIGICARE;
11.2.2. Modificação não autorizada ou uso da Plataforma em desacordo com as regras e disposições destas Condições Gerais e/ou das instruções que sejam fornecidas pela TISAÚDE;
11.2.3. Perda das senhas de acesso do Usuário.
11.3. A MyDIGICARE não se responsabiliza por danos causados ao sistema por vírus de computador, falhas de energia elétrica, interrupções na conexão da Internet, atualizações ou falhas inerentes ao servidor de hospedagem terceirizado ou qualquer outra causa que não seja associada diretamente à MyDIGICARE
11.4. A MyDIGICARE não garante que a Plataforma está livre de erros. A MyDIGICARE, no entanto, envidará seus maiores esforços para sanar qualquer erro de programação que a Plataforma venha a apresentar.
11.5. Assume-se, para efeito destas condições gerais, e o usuário expressamente concorda, a existência de um consenso mundial acerca de não haver programa de computador totalmente isento de erros ou totalmente invulnerável; à luz de tal consenso, na hipótese da ocorrência de qualquer defeito no software, a MyDIGICARE se reserva o direito de ser chamada para tentar resolvê-lo antes que lhe seja atribuída qualquer responsabilidade ou se lhe requeira qualquer ressarcimento ou indenização.
11.6. Esta garantia pressupõe que a Plataforma, objeto deste contrato, seja operado em ambiente de software e hardware de origem idônea, em sistema de rede e instalações elétricas adequadas e isentas de problemas, e dentro da configuração mínima exigida pela MyDIGICARE. Considera-se hardware não idôneo aquele originário de fabricante desconhecido ou não legalmente constituído, ou montado em instalações clandestinas, originário de contrabando ou qualquer outro em que não exista garantia expressa e válida de fabricação de seu todo ou partes.
11.7. As garantias estipuladas na presente cláusula não abrangem problemas, erros, danos ou prejuízos advindos de decisões tomadas com base em informações, quaisquer que sejam fornecidas pelo(s) programa(s), assim como não abrangem defeitos ou erros decorrentes de negligência, imprudência ou imperícia do Usuário na utilização da Plataforma, assim como, problemas provenientes de caso fortuito ou força maior.
11.8. O Usuário é integralmente responsável pelas informações inseridas na Plataforma. Toda e qualquer informação inserida na Plataforma pelo Usuário não será, em hipótese alguma, revisada pela MyDIGICARE. A MyDIGICARE, em hipótese alguma, será responsável pelo conteúdo inserido pelo Usuário na Plataforma.
11.9. Em nenhuma circunstância a MyDIGICARE será considerada responsável por quaisquer danos, diretos ou indiretos, físicos, técnicos, econômicos, ou fiscais, tais como, sem se resumir a, perdas e danos, lucros cessantes, danos emergentes, interrupção de negócios ou outros prejuízos decorrentes de uso ou da impossibilidade do uso da Plataforma. 
11.10. Mesmo não respondendo por perdas e danos, lucros cessantes ou prejuízos decorrentes do funcionamento da Plataforma, em caso de discussão ou disputa judicial entre as Partes na qual se alegue a invalidade ou a não aplicação da cláusula de isenção de responsabilidade acima, a responsabilidade civil da MyDIGICARE será limitada ao valor anual pago pelo Usuário à MyDIGICARE em razão da licença de uso.
11.11. A MyDIGICARE poderá, a qualquer tempo, alterar, adequar ou remodelar o conteúdo, layout, funcionalidades e/ou ferramentas relacionadas à plataforma, desde que esteja em conformidade com a legislação brasileira, em especial a Lei Federal nº 12.965 de 2014 (Marco Civil da Internet) e a Lei nº 13.709 de 2018 (Lei Geral de Proteção de Dados).
12. DOS DADOS PESSOAIS TRATADOS PELA PLATAFORMA MyDIGICARE FARMA
12.1. A MyDIGICARE possui o ANEXO 1 sobre tratamento de dados , bem como a Política de Privacidade, que regula o tratamento dos dados coletados na plataforma MyDIGICARE, sendo partes integrantes e inseparáveis do presente Termo, e pode ser acessada pelo ( em elaboração)
12.2. Caso alguma disposição sobre tratamento de dados pessoais da Política de Privacidade conflite com qualquer outra do presente documento, deverá prevalecer o descrito no documento mais específico.
13. DEMAIS CONDIÇÕES
13.1. O Usuário concorda que a MyDIGICARE lhe envie ou simplesmente disponibilize, por meio da Plataforma objeto deste Contrato, artigos, textos, notícias ou quaisquer outras formas de comunicação, desenvolvidas pela própria MyDIGICARE ou por parceiros desta, sempre que a MyDIGICARE julgar que tais comunicações sejam de interesse do Usuário. 
13.2. A MyDIGICARE poderá modificar estas Condições Gerais a qualquer momento, publicando a nova versão em seu website. O Contrato revisado entrará em vigor dentro de 30 (trinta) dias contados da data de sua publicação ou entrega ao Usuário, exceto se o Usuário expressamente aceitar o contrato revisado antes disso ao clicar no botão de aceitar. A aceitação expressa ou o uso contínuo da Plataforma depois da expiração do prazo de 30 (trinta) dias do aviso constituirá aceitação integral destas Condições Gerais. 
13.3. As disposições destas Condições Gerais são irrevogáveis e irretratáveis e obrigam as partes e seus sucessores a qualquer título. As partes reconhecem, para todos os fins e efeitos de direito, que as presentes Condições Gerais constituem título executivo extrajudicial, estando sujeito à execução específica, nos termos do disposto no Código de Processo Civil.
13.4. Fica eleito o foro da Comarca de Porto Alegre com renúncia de qualquer outro, por mais privilegiado que o seja, para resolver qualquer questão resultante do presente Contrato.

ANEXO 1 SOBRE TRATAMENTO DE DADOS PESSOAIS
1. PREÂMBULO
1.1. No âmbito do presente termo, uma Parte pode realizar o Tratamento dos Dados Pessoais dos colaboradores, clientes ou terceiros da outra Parte. Para prestação dos serviços e/ou fornecimento de produtos, o USUÁRIO fornecerá Dados Pessoais à MyDIGICARE ou esta obterá/coletará Dados Pessoais em nome do USUÁRIO, figurando a MyDIGICARE como Operador de tais Dados Pessoais. Já no que diz respeito aos dados pessoais dos seus representantes legais, prepostos e terceiros de qualquer forma envolvidos com o Contrato, a TISAÚDE os fornecerá ao USUÁRIO, figurando como Controlador de tais Dados Pessoais.
1.2. No desenvolvimento de quaisquer atividades de Tratamento de Dados Pessoais relacionadas com o presente termo, o USUÁRIO e a MyDIGICARE se comprometem a observar e a cumprir as disposições elencadas no presente termo, bem como as disposições da LGPD e a qualquer outra legislação que, de alguma forma, regule a proteção de Dados Pessoais e seja aplicável à presente relação entre as partes. 
2. OBRIGAÇÕES DA MyDIGICARE
2.1. A MyDIGICARE, na qualidade de Controlador dos Dados Pessoais, concorda que:
(a) é o responsável pelo Tratamento de Dados Pessoais em conformidade com a LGPD, devendo fornecer apenas Dados Pessoais devidamente legitimados para Tratamento pelo USUÁRIO no âmbito desde Contrato, bem como cumprir todas as obrigações que a LGPD lhe atribui na qualidade de Controlador dos Dados Pessoais;
(b) manterá registro de toda a atividade de Tratamento de Dados Pessoais de acordo e na forma da LGPD;
(c) implementou as Medidas de Segurança, Técnicas e Administrativas adequadas de modo que o Tratamento de Dados Pessoais cumpra os requisitos descritos na LGPD, bem como assegura a proteção dos direitos do Titular dos Dados Pessoais antes de realizar qualquer operação de Tratamento deles;
(d) informará, em prazo razoável, ao USUÁRIO sobre mudanças no Tratamento de Dados Pessoais que possam afetar as suas obrigações contratuais. Além disso, também deverá informar à MyDIGICARE sobre ações tomadas por terceiros, bem como pela Agência Nacional de Proteção de Dados (ANPD), com relação ao Tratamento de Dados Pessoais que eventualmente gerem algum impacto para o presente termo;
(e) será responsável por responder e satisfazer todas e quaisquer reclamações advindas do Titular dos Dados Pessoais e da ANPD, que estejam relacionadas com o Tratamento dos Dados Pessoais, nos termos da LGPD e do presente termo;
(f) é responsável por garantir que os Dados Pessoais fornecidos por qualquer subcontratado autorizado estejam devidamente legitimados para o Tratamento no âmbito do presente termo.
2.2. A MyDIGICARE, na qualidade de Operador dos Dados Pessoais fornecidos pelo USUÁRIO ou coletados em nome dela, concorda e garante que:
(a) realiza o Tratamento de Dados Pessoais, conforme indicado pelo USUÁRIO, única e exclusivamente para execução de suas obrigações previstas neste termo e para o cumprimento de obrigações legais, tratando estes Dados Pessoais apenas no limite do necessário para o bom e fiel cumprimento de tais obrigações, estando expressamente proibido o uso dos Dados Pessoais para fins distintos daqueles aqui previstos;
(b) não pode retificar, alterar ou restringir o Tratamento de Dados Pessoais por sua própria iniciativa, mas somente mediante instruções devidamente documentadas do USUÁRIO, enquanto perdurar o termo;
(c) implementou as Medidas de Segurança, Técnicas e Administrativas adequadas e compatíveis com a categoria de Dados Pessoais e o tipo de risco decorrente do Tratamento, de modo que a cumprir os requisitos descritos na LGPD, bem como assegurar a proteção dos direitos do Titular dos Dados Pessoais contra, incluindo, mas não se limitando, uso não autorizado ou ilícito, perda e destruição, antes de realizar qualquer operação de Tratamento destes Dados Pessoais. Medidas de segurança adicionais podem ser solicitadas pelo USUÁRIO ou exigidas pela legislação;
(d) mantem em sigilo todos os Dados Pessoais, que são considerados informações confidenciais, sendo estas utilizadas exclusivamente nos estritos limites deste termo;
(e) cumprirá as políticas e normas do USUÁRIO sobre privacidade, proteção de Dados Pessoais e segurança da informação;
(f) Informará ao USUÁRIO imediatamente sobre:
(i) qualquer pedido de divulgação dos Dados Pessoais por uma autoridade pública ou por ordem/determinação judicial, a menos que esteja proibida por lei;
(ii) qualquer ocorrência com Dados Pessoais;
(iii) qualquer solicitação apresentada pelos Titulares dos Dados Pessoais sem responder a esse pedido, a menos que tenha sido autorizada a fazê-lo pelo USUÁRIO.
2.2.1. A MyDIGICARE concede ao USUÁRIO acesso a qualquer informação que detenha em seu nome e aos sistemas utilizados para esse fim, a menos que seja proibido pela legislação vigente sobre proteção de dados.
2.2.2. O USUÁRIO, caso necessário, poderá fornecer à MyDIGICARE todas as informações necessárias para responder a qualquer questionamento de qualquer órgão ou autoridade que atue em defesa ou na proteção de Dados Pessoais, bem como atender aos requisitos exigidos pela legislação aplicável de notificação de violação de Dados Pessoais às autoridades de proteção de Dados Pessoais e aos seus. 
3. OBRIGAÇÕES DO USUÁRIO
3.1. O USUÁRIO, na qualidade de Operador dos Dados Pessoais fornecidos pela MyDIGICARE, realizará o Tratamento dos Dados Pessoais no limite do necessário para gerir e executar a relação contratual mantida com ela, na qualidade de prestadora de serviços e/ou fornecedora de produtos, de boa-fé, partindo da premissa de que todos os Dados Pessoais que lhe forem entregues para Tratamento pela MyDIGICARE estão devidamente legitimados conforme a LGPD, responsabilizando-se somente caso submeta estes Dados Pessoais a Tratamento fora do contexto do presente Contrato, de suas obrigações legais ou regulatórias ou do exercício regular de seus direitos. 
3.2. O USUÁRIO concorda e garante que informará à MyDIGICARE imediatamente sobre:
(i) qualquer pedido de divulgação dos Dados Pessoais por uma autoridade pública, a menos que esteja proibida por lei; 
(ii) qualquer ocorrência com Dados Pessoais;
(iii) qualquer solicitação apresentada pelos Titulares dos Dados Pessoais.
4. ATENDIMENTO DE SOLICITAÇÃO DA AUTORIDADE NACIONAL DE PROTEÇÃO DE DADOS (ANPD)
4.1. Cada Parte deverá atender às solicitações realizadas pela Autoridade Nacional de Proteção de Dados (ANPD) em relação ao Tratamento dos Dados Pessoais fornecidos para outra Parte no âmbito do Contrato, devendo a Parte que figurar como Operador colaborar com o Controlador, fornecendo esclarecimentos necessários para atendimento das referidas solicitações.
5. TRANSFERÊNCIA DE DADOS PESSOAIS
5.1. No curso de suas atividades regulares, a MyDIGICARE realiza transferência de Dados Pessoais para o cumprimento do presente termo, de obrigações legais e/ou com base no interesse legítimo. Desta forma, desde já, o USUÁRIO autoriza a MyDIGICARE a transferir Dados Pessoais para destinatários localizados inclusive em outro país exclusivamente para fins de backup, desde que o país envolvido na transferência de dados seja reconhecido como tendo um nível adequado de proteção legal para os direitos e liberdades dos Titulares de Dados Pessoais, ou que as medidas de segurança sejam garantidas contratualmente através de certificado de segurança. 
6. OCORRÊNCIA COM DADOS PESSOAIS
6.1. A MyDIGICARE deverá comunicar imediatamente ao USUÁRIO qualquer ocorrência, concreta ou suspeita, ilícita ou acidental, com os Dados Pessoais que estiver tratando em decorrência do Contrato, tais como, mas sem se limitar, divulgação destruição, uso indevido, perda, roubo, alteração e cessão (“ocorrência”).
6.1.1. A comunicação acima mencionada conterá, no mínimo, as seguintes informações:
(i) descrição da natureza da ocorrência com Dados Pessoais, data e hora;
(ii) data e hora da ciência da ocorrência pela MyDIGICARE;
(iii) categorias ou tipos de Dados Pessoais afetados pela ocorrência;
(iv) número de Titulares dos Dados Pessoais afetados;
(v) relação de Titulares de Dados Pessoais afetados pelo vazamento;
(vi) dados de contato do Encarregado de Proteção de Dados (DPO) ou outra pessoa com a qual seja possível obter maiores informações sobre a ocorrência;
(vii) descrição das possíveis consequências ou riscos decorrentes da ocorrência;
(viii) indicação das medidas que podem ser adotadas para reverter ou mitigar as consequências da ocorrência, bem como aquelas para evitar novas ocorrências.
6.1.2. Caso, no momento da comunicação, a MyDIGICARE não tenha todas as informações acima indicadas, deverá enviá-las ao USUÁRIO de forma gradual, com a maior agilidade e rapidez.
6.2. A MyDIGICARE cooperará amplamente com o USUÁRIO no sentido de facilitar a investigação e adotará todas as providências exigidas por esta para remediação de uma ocorrência com Dados Pessoais.
6.3. A MyDIGICARE será a responsável por adotar todas as medidas corretivas necessárias e apropriadas para investigar e remediar ou atenuar quaisquer ocorrências com Dados Pessoais.
7. CONFIDENCIALIDADE
7.1. Ainda que exista previsão em contrário neste termo, a MyDIGICARE está obrigada a manter em sigilo todos os Dados Pessoais, que são considerados informações confidenciais, para todos os fins.
7.2. A MyDIGICARE deverá adotar todas medidas para que seus prepostos, sejam eles empregados, terceiros contratados, representantes, etc, os quais tenham acesso ou estejam envolvidos com Tratamento de Dados Pessoais no âmbito deste termo: (i) sejam informados sobre e cumpram as obrigações previstas neste termo acerca da proteção dos Dados Pessoais; (ii) assumam e cumpram o dever de confidencialidade.
7.3. A obrigação de confidencialidade se aplica e permanece vigente após o término do termo, por qualquer motivo, por prazo indeterminado.
8. OBRIGAÇÃO DECORRENTE DO TÉRMINO DO TRATAMENTO DE DADOS PESSOAIS
8.1. Ao término do Contrato por qualquer motivo, a CONTRATADA deverá cessar o Tratamento dos Dados Pessoais fornecidos pela CONTRATANTE, efetuando a sua exclusão definitiva com segurança, ou devolvendo para a CONTRATANTE, a critério deste último, no prazo de até 30 (trinta) dias do requerimento previsto no item 8.1.1, todos os Dados Pessoais ou cópias de documentos que contenham Dados Pessoais, em qualquer base ou suporte, guardando a comprovação da exclusão/devolução a qual poderá ser solicitada pela CONTRATANTE a qualquer momento, a menos que a sua manutenção seja exigida ou assegurada pela legislação vigente aplicável. Nesta última hipótese, a CONTRATADA garantirá a confidencialidade das informações por prazo indeterminado.
8.1.1. A devolução ao CONTRATANTE dos Dados Pessoais ou cópias de documentos que contenham Dados Pessoais, está condicionada ao envio de requerimento, exclusivamente por parte do CONTRATANTE, ao contato suporte@mydigicare.com.br, servindo a resposta ao referido requerimento por e-mail, constando o link de acesso com as informações do CONTRATANTE, como comprovante de entrega do banco de dados.
8.1.2. A MyDIGICARE apenas utilizará a base de dados gerada a partir da execução dos serviços de forma anonimizada, ou seja, sem identificação dos titulares de dados, e dentro dos limites dispostos na Lei Geral de Proteção de Dados para o aprimoramento dos serviços desempenhados, desenvolvimento de pesquisas, informações estatísticas, relatórios de desempenho e procedimentos de gestão.
9. INDICAÇÃO DO ENCARREGADO DE PROTEÇÃO DE DADOS (DPO)
9.1. A MyDIGICARE disponibiliza ao USUÁRIO os dados de contato do Encarregado de Proteção de Dados (DPO), conforme e-mail contato@mydigicare.com.br, bem como disponibiliza tais informações em seu site www.mydigicare.com.br/privacidade/


<b>Política de Privacidade</b>
POLÍTICA DE PRIVACIDADE DA PLATAFORMA MyDIGICARE
A presente Política de Privacidade tem por finalidade demonstrar o compromisso da MyDIGICARE, PRESTAÇÃO DE SERVIÇOS FARMACÊUTICOS LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 36.160.501/0001-82, com sede de sua matriz na Av. Luiz Manoel Gonzaga, 187/1004, Petrópolis, Porto Alegre, RS , CEP 90470280 titular da plataforma e sites MyDIGICARE, doravante denominada MyDIGICARE , com a privacidade e a proteção dos dados pessoais coletados de seus USUÁRIOS, estabelecendo as regras sobre o tratamento dos dados dentro do escopo dos serviços e funcionalidades do MyDIGICARE, de acordo com as leis em vigor, com transparência e clareza junto ao USUÁRIO e ao mercado em geral.  
Como condição para acesso e uso das funcionalidades exclusivas da MyDIGICARE, o USUÁRIO declara que fez a leitura completa e atenta da presente Política de Privacidade, estando plenamente ciente, conferindo, assim, sua livre e expressa concordância com os termos aqui estipulados, autorizando a obtenção dos dados aqui mencionados, bem como sua utilização para os fins abaixo especificados. Caso não esteja de acordo com estas diretivas, o USUÁRIO deverá descontinuar o seu acesso.
1.Definições
1.1. Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento: COOKIES: pequenos arquivos de computador ou pacotes de dados enviados por um site da Internet para o navegador do usuário, quando o USUÁRIO visita o site.
IP: Abreviatura de Internet Protocol. É conjunto alfanumérico que identifica os dispositivos dos USUÁRIOS na Internet. USUÁRIO: Qualquer pessoa física que acessa e/ou utiliza as funcionalidades e/ou serviços da MyDIGICARE.
2. Coleta e Uso de Dados e Registro de Atividades
2.1. Os dados coletados a partir da submissão voluntária pelo USUÁRIO ou automaticamente atendem ao princípio da necessidade e podem incluir:


Usuário Farmacêutico
Categoria	Dados	Finalidade
Cadastrais obrigatórios	Nome, CPF, email, telefone, inscrição no conselho regional de farmácia	Identificação e autenticação do Usuário na plataforma
Tratamento de saúde 
Realização de consultas farmacêuticas à distância
Emissão de prescrições farmacêuticas, encaminhamentos para outros profissionais de saúde, Teleinterconsulta, Telemonitoramento
Receber comunicados ou atualizações sobre a MyDIGICARE e seus produtos
Cadastrais facultativos	Endereço e nome do local de atendimento	Identificação e autenticação do Usuário na plataforma
Tratamento de saúde 
Realização de consultas farmacêuticas à distância
Emissão de prescrições farmacêuticas, encaminhamentos para outros profissionais de saúde, Teleinterconsulta, telemonitoramento
Receber comunicados ou atualizações sobre a MyDIGICARE e seus produtos
Dados de saúde, imagem de prescrições, exames, imagem de medicamentos em uso, boletim de atendimento	Características biológicas, tratamentos em andamento, medicamentos usados e em uso, doenças e outras condições de saúde
relacionadas ou não a fatores genéticos	Tratamento de saúde 
Realização de consultas farmacêuticas à distância (teleconsulta)
Emissão de prescrições farmacêuticas, Teleinterconsulta, Telemonitoramento 
Pagamento	Dados do cartão de crédito	Contratação de módulos de serviços na plataforma MyDIGICARE FARMA
Registros eletrônicos	IP, Cookies, registros de atividades	Registro de acesso para fins de cumprimento de obrigação legal
Analytics no site MyDIGICARE
Estatísticos (anonimizados)	Dados de uso da plataforma
(não são considerados dados pessoais)	Analytics de uso da plataforma MyDIGICARE
Usuário Recepcionista e/ou Administrativo (s)
Categoria	Dados	Finalidade
Cadastrais obrigatórios	Nome, CPF, email, sexo	Identificação e autenticação do Usuário na plataforma
Receber comunicados ou atualizações sobre a MyDIGICARE e seus produtos
Usuário Paciente
Categoria	Dados	Finalidade
Cadastrais obrigatórios	Nome completo, celular, data de nascimento, CPF	Identificação e autenticação do Usuário na plataforma
Tratamento de saúde
Realização de consultas farmacêuticas à distância
Receber comunicados ou atualizações sobre a MyDIGICARE e seus produtos
Cadastrais opcionais	Email, UF, Cidade, Bairro	Identificação e autenticação do Usuário na plataforma
Tratamento de saúde
Realização de consultas farmacêuticas à distância
Receber comunicados ou atualizações sobre a MyDIGICARE e seus produtos
Registros eletrônicos	IP, Cookies, registros de atividades	Registro de acesso para fins de cumprimento de obrigação legal
Analytics no site MyDIGICARE
Estatísticos (Anonimizados)	Dados de uso da plataforma (não são considerados dados pessoais)	Analytics de uso da plataforma MyDIGICARE
Inteligência de Mercado



2.2. Todos os dados pessoais poderão ser utilizados como meio probatório em casos de atos ilícitos ou contrários a esta Política de Privacidade ou qualquer outro documento legal disponibilizado pela MyDIGICARE, bem como para cumprimento de ordem judicial ou de requisição administrativa.
2.2.1. Cabe ao USUÁRIO configurar o seu dispositivo móvel caso deseje bloquear a coleta de cookies ou outros dados. Nesta hipótese, algumas funcionalidades da MyDIGICARE poderão ser limitadas.
2.3. A MyDIGICARE não é responsável pela precisão, veracidade ou falta delas nas informações prestadas pelo USUÁRIO ou pela sua desatualização, sendo de responsabilidade do USUÁRIO prestá-las com exatidão e atualizá-las sempre que necessário.
2.4. A base de dados formada por meio da coleta de dados na MyDIGICARE é de propriedade e responsabilidade da MyDIGICARE, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos limites e propósitos dos negócios da MyDIGICARE e descritos nesta Política de Privacidade e Termos de Uso.
2.4.1. O USUÁRIO é corresponsável pelo sigilo de seus dados pessoais. O compartilhamento de senhas e dados de acesso viola esta Política de Privacidade e os Termos de Uso da MyDIGICARE 
2.5. Internamente, os dados dos USUÁRIOS serão acessados somente por profissionais devidamente autorizados pela MyDIGICARE respeitando os princípios de proporcionalidade, necessidade e relevância para os objetivos da MyDIGICARE, além do compromisso de confidencialidade e preservação da privacidade nos termos desta Política de Privacidade.
3. Armazenamento, Retenção e Eliminação dos Dados
3.1. Os dados coletados são armazenados em nuvem (cloud computing) da Hostgator 
3.2. Todos os dados coletados são armazenados em ambiente seguro. Todavia, considerando que nenhum sistema de segurança é infalível, a MyDIGICARE se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões do banco de dados da MyDIGICARE, salvo nos casos em que incorrer em dolo ou culpa.
3.3. Para fins de auditoria, segurança, controle de fraudes, preservação de direitos e cumprimento de obrigação legal, a MyDIGICARE poderá permanecer com o histórico de registros de acesso dos USUÁRIOS por prazo mínimo de 6 (seis) meses. A guarda dos dados relacionados ao prontuário do paciente é de responsabilidade integral da clínica ou profissional de saúde, conforme a legislação específica do setor.
3.3.1. Ao término do contrato entre USUÁRIO e MyDIGICARE FARMA, o USUÁRIO Administrador da Conta é integralmente responsável por realizar a exportação de todos os dados inseridos em sua conta na plataforma MyDIGICARE FARMA dentro do prazo de 60 (sessenta) dias a contar do término da assinatura.
3.3.2. Caso haja solicitação de eliminação dos dados pelo USUÁRIO, essa eliminação somente poderá ocorrer se já não houver mais finalidade de uso nem obrigação legal, regulatória ou judicial que justifique sua retenção. 
3.3.3. Os dados deverão ser retidos enquanto durar sua finalidade de uso ou obrigação legal, regulatória ou judicial que justifique sua retenção. Ao término da finalidade de uso e o prazo de retenção obrigatória, os dados poderão ser eliminados com uso de métodos de descarte seguro, ou utilizados de forma anonimizada para fins estatísticos.
4. Compartilhamento e Exportação dos Dados
4.1. Os dados coletados e as atividades registradas poderão ser compartilhados: i) Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver requisição legal das autoridades ou ordem judicial;
ii) De forma automática em caso de movimentações societárias, como fusão, aquisição ou incorporação, e
iii) De forma automática, com os provedores dos serviços contratados pela MyDIGICARE para viabilizar o site e plataforma MyDIGICARE juntamente com todas as suas funcionalidades e serviços disponibilizados.
4.2. Todos os terceiros contratados para a finalidade do item “iii” acima obrigam-se a tratar os dados com confidencialidade e somente para a finalidade contratada, assegurando o cumprimento legal em matéria de privacidade e proteção de dados e utilizando, em todo o tempo, as melhores práticas de segurança da informação.
4.3. Poderá, ainda, haver compartilhamento prontuários entre os profissionais de saúde de uma mesma clínica, a depender das regras estabelecidas pela clínica para acesso aos dados e prestação dos serviços de cuidado em saúde.
 4.4. Os dados de prontuários somente poderão ser exportados mediante autorização do USUÁRIO Administrador da Conta da clínica na plataforma, o qual será integralmente responsável pela legitimação da operação. 
5. Consentimento
Neste tópico, você verificará os aspectos do seu consentimento coletado pela MyDIGICARE, bem como a forma de alterar suas permissões.
5.1. O consentimento fornecido pelo USUÁRIO é coletado de forma livre, informada, destacada, específica e legítima.
5.2. O USUÁRIO poderá alterar suas concessões de consentimento, conceder novas permissões ou retirar seu consentimento para as permissões atuais por meio dos canais de atendimento da MyDIGICARE disponibilizados no site e na plataforma, sendo avisado sobre as consequências que a retirada de consentimento poderá lhe causar.
6. Exibição, Retificação, Portabilidade, Limitação, Oposição e Eliminação de Dados
Aqui, você poderá entender melhor como exercer seus direitos previstos pela Lei Geral de Proteção de Dados Pessoais (LGPD), e a condição para a portabilidade dos prontuários dos pacientes.
6.1. O USUÁRIO poderá ter acesso aos seus dados (exibição) e realizar a retificação de seus dados pessoais por meio do ambiente logado na plataforma MyDIGICARE FARMA ou pelos canais de atendimento disponibilizados pela MyDIGICARE
6.2. Pelo canal de atendimento da MyDIGICARE o USUÁRIO poderá também requerer: (i) a limitação do uso de seus dados pessoais; (ii) manifestar sua oposição ao uso de seus dados pessoais ou (iii) solicitar a eliminação de seus dados pessoais coletados
pela MyDIGICARE, desde que a eventual relação contratual entre o USUÁRIO e a MyDIGICARE tenha terminado, não haja nenhuma finalidade de uso que legitime o tratamento e tenha decorrido o prazo legal mínimo relacionado à retenção dos dados, conforme tópico 3.3 desta Política de Privacidade.
6.3. Tendo em vista o papel de Operador que a MyDIGICARE desempenha em relação aos dados de pacientes, a portabilidade dos dados de prontuário deverá ser solicitada pelo próprio paciente ao profissional de saúde que o atende ou à clínica em que realize tratamento de saúde, responsável pela guarda dos dados dos prontuários.
7. Segurança
Quais são as medidas implementadas para proteger seus dados pessoais.
7.1. A MyDIGICAR, trata os dados pessoais de acordo com as melhores práticas de segurança da informação e, em especial, os armazena na nuvem da Hostgator.
7.2. A própria MyDIGICARE também aplica medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão, observados a estrutura, a escala e o volume de suas operações, bem como a sensibilidade dos dados tratados e a probabilidade e a gravidade dos danos para os titulares dos dados. Dentre tais medidas, destacam-se o uso de técnicas de SALT para a codificação da senha, backup, segregação dos dados por organização e controle de acesso aos dados com base na necessidade e permissão concedida pelo USUÁRIO.
8. Disposições Gerais
8.1. A MyDIGICARE não utiliza nenhum tipo de decisão automatizada que impacte o USUÁRIO.
8.2. A MyDIGICARE reserva a si o direito de alterar o teor desta Política de Privacidade a qualquer momento, conforme a finalidade ou necessidade, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo ao USUÁRIO verificá-la sempre que efetuar o acesso à MyDIGICARE.
8.2.1. Ocorrendo atualizações neste documento e que necessariamente demandem nova coleta de consentimento, a MyDIGICARE notificará o USUÁRIO pelos meios de contato por ele fornecidos.
8.3. Em caso de qualquer dúvida com relação às disposições constantes nesta Política de Privacidade ou necessidade de contato com o Encarregado pelo Tratamento de Dados Pessoais, o USUÁRIO poderá entrar em contato com pelo e-mail contato@mydigicare.com.br, sendo que as respostas ao contato serão enviadas entre segunda e sexta-feira, no horário comercial, exceto em feriados.
8.4. Caso empresas terceirizadas realizem o tratamento de quaisquer dados coletados pela MyDIGICARE, deverão respeitar as condições aqui estipuladas e as melhores práticas de segurança da informação, obrigatoriamente.
8.5. Caso alguma disposição desta Política de Privacidade seja considerada ilegal ou ilegítima por autoridade da localidade em que o USUÁRIO resida, as demais condições permanecerão em pleno vigor e produção de efeitos.
8.6. O USUÁRIO reconhece que toda comunicação realizada qualquer forma eletrônica é válida como prova documental, sendo eficaz e suficiente para a divulgação de qualquer assunto a que se refira aos serviços prestados pela MyDIGICARE, bem como às condições de sua prestação ou a qualquer outro assunto nele abordado, ressalvadas as disposições expressamente diversas previstas nesta Política de Privacidade.
9. Lei Aplicável e Jurisdição
9.1. O presente documento será regido e interpretado segundo a legislação brasileira, no idioma português, sendo eleito o foro do domicílio do USUÁRIO para dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
            </p>
            <div className="button-box">
            <Button variant="contained" onClick={confirmarCadastro} className="modal-button">
              Confirmar
            </Button>
            </div>
            </Typography>

          </Box>
          
        </Modal>
    
        <Modal
          open={openConfirmation}
          onClose={handleCloseConfirmation}
          aria-labelledby="modal-confirmation-title"
          aria-describedby="modal-confirmation-description"
          className="modal"
        >
          <Box className="modal-box">
            <Typography id="modal-confirmation-title" variant="h6" component="h2">
              Confirmação de E-mail
            </Typography>
            <Typography id="modal-confirmation-description" className="modal-description" sx={{ mt: 1 }}>
              Código de Confirmação de E-mail enviado para {email}. Por favor, forneça o código abaixo.
            </Typography>
            <TextField
              autoComplete="codigo"
              name="codigo"
              required
              fullWidth
              id="codigo"
              label="Código de Confirmação"
              autoFocus
              value={codigoConfirmacao}
              onChange={(e) => setCodigoConfirmacao(e.target.value)}
              sx={{ mt: 7 }}
            />
            <Button variant="contained" className="modal-button" sx={{ mt: 3 }} onClick={verificarCodigo}>
              Verificar Código
            </Button>
          </Box>
        </Modal>
      </>
    );
  };
  
  export default CadastroFarmaceutico;
  
