import React, { useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import './EsqueceuSenha.css';

const EsqueceuSenha = () => {
  const [email, setEmail] = useState("");
  let history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/send-confirmation-email", { email })
      .then((response) => {
        alert(response.data.message);
        history.push("/recuperacaoemail"); // Redireciona para a página de verificação de código
      })
      .catch((error) => {
        alert("Erro ao enviar e-mail de recuperação: " + error.message);
      });
  };

  return (
    <div className="container-esqueceu-senha">
      <Container component="main" maxWidth="xs" className="card-esqueceu-senha">
        <Typography component="h1" variant="h5">
          Esqueceu sua senha?
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Prosseguir
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default EsqueceuSenha;
