import Axios from "axios";
import React, { useState } from "react";
import SidebarAdministrativo from "../../components/sidebar_administrativo/Sidebar_Administrativo";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";

const AlterarSenha = (props) => {
  let history = useHistory();
  Axios.defaults.withCredentials = true;

  const [cpfUsuario, setCpfUsuario] = useState("");
  const [senhaNova, setSenhaNova] = useState("");

  const alteraSenha = () => {
    Axios.defaults.withCredentials = false;

    Axios.post("https://atendimento.mdhmlg.vsoares.dev.br:3002/alterarSenhaUsuariosAdmin", {
      cpf: cpfUsuario,
      nova_senha: senhaNova,
    }).then((response) => {
      if (response.data.message === "1") {
        alert("Senha alterada com sucesso!");
        history.push("/adm/dashboard");
      } else {
        if (response.data.message === "0") {
          alert("Erro ao alterar senha!");
        }
      }
    });
  };

  return (
    <>
      <SidebarAdministrativo>
        <div className="main__container">
          <div className="form">
            <div className="titulo">
              <h3>ALTERAR SENHA</h3>
            </div>
            <div className="div_teste">
              <div className="item">
                <p>CPF Usuário: </p>
                <InputMask
                  mask="999.999.999-99"
                  class="nome"
                  onChange={(e) => {
                    setCpfUsuario(e.target.value);
                  }}
                ></InputMask>
              </div>
              <div className="item">
                <p>Nova senha: </p>
                <input
                  type="password"
                  onChange={(e) => {
                    setSenhaNova(e.target.value);
                  }}
                ></input>
              </div>
              <div className="item">
                <p></p>
                <button className="teste" onClick={alteraSenha}>
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </SidebarAdministrativo>
    </>
  );
};

export default AlterarSenha;
